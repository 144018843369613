/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any }
}

export enum AdvisorSortEnum {
  Clients = 'CLIENTS',
  JobsAdded = 'JOBS_ADDED',
  JobsShared = 'JOBS_SHARED',
}

export type AdvisorUsers = {
  __typename?: 'AdvisorUsers'
  id: Scalars['ID']['output']
  jobsAdded: Array<UsersJobs>
  jobsShared: Array<UsersJobs>
  user: Users
}

export enum AuthProviderTypeEnum {
  Google = 'GOOGLE',
}

export type AuthResponse = {
  __typename?: 'AuthResponse'
  token?: Maybe<Scalars['ID']['output']>
  user?: Maybe<Users>
}

export type CachedStreamingType = {
  __typename?: 'CachedStreamingType'
  isSync: Scalars['Boolean']['output']
  result: Scalars['String']['output']
}

export type ClientGroup = {
  __typename?: 'ClientGroup'
  clients: Array<ClientUsers>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByUser: Users
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ClientUsers = {
  __typename?: 'ClientUsers'
  addedJobs: Array<UsersJobs>
  allJobs: Array<UsersJobs>
  appliedJobs: Array<UsersJobs>
  id: Scalars['ID']['output']
  interviewedJobs: Array<UsersJobs>
  numAddedJobs: Scalars['Int']['output']
  numAllJobs: Scalars['Int']['output']
  numAppliedJobs: Scalars['Int']['output']
  numInterviewedJobs: Scalars['Int']['output']
  numOfferedJobs: Scalars['Int']['output']
  numRejectedJobs: Scalars['Int']['output']
  offeredJobs: Array<UsersJobs>
  rejectedJobs: Array<UsersJobs>
  user: Users
}

export type Contacts = {
  __typename?: 'Contacts'
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type ContactsInputObject = {
  email: Scalars['String']['input']
  name: Scalars['String']['input']
}

export enum CoverLetterLengthEnum {
  Long = 'LONG',
  Medium = 'MEDIUM',
  Short = 'SHORT',
}

export enum CoverLetterToneEnum {
  Casual = 'CASUAL',
  Excited = 'EXCITED',
  Formal = 'FORMAL',
  Informational = 'INFORMATIONAL',
}

export type FieldMessageType = {
  __typename?: 'FieldMessageType'
  errorMessage?: Maybe<Scalars['String']['output']>
  field: Scalars['String']['output']
}

export type Interview = {
  __typename?: 'Interview'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  interviewType: InterviewsTypeEnum
  jobTitle: Scalars['String']['output']
  messages: Array<InterviewsMessage>
  score?: Maybe<Scalars['Int']['output']>
  status: InterviewsStatusEnum
}

export type InterviewsMessage = {
  __typename?: 'InterviewsMessage'
  fromUser: Scalars['Boolean']['output']
  message: Scalars['String']['output']
}

export enum InterviewsStatusEnum {
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  Started = 'STARTED',
}

export enum InterviewsTypeEnum {
  IntroRecruiterScreen = 'INTRO_RECRUITER_SCREEN',
  JobInterviewBehavioralTechnical = 'JOB_INTERVIEW_BEHAVIORAL_TECHNICAL',
}

export enum JobPostingSortEnum {
  ClientsApplied = 'CLIENTS_APPLIED',
  ClientsSharedWith = 'CLIENTS_SHARED_WITH',
}

/** Autogenerated return type of LearnSkills. */
export type LearnSkillsPayload = {
  __typename?: 'LearnSkillsPayload'
  content: Scalars['String']['output']
  i?: Maybe<Scalars['Int']['output']>
  initial: Scalars['Boolean']['output']
  requestId: Scalars['String']['output']
  usersJobId: Scalars['ID']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  addContactToUsersJob: UsersJobs
  addUsersToClientGroup: ClientGroup
  assignClientToAdvisor: Scalars['String']['output']
  authPasswordResetRequest: Scalars['String']['output']
  authProviderAuthenticate: AuthResponse
  authResetPassword: Scalars['String']['output']
  authSignIn: AuthResponse
  authSignUp: AuthResponse
  billingPortalUrl: Scalars['String']['output']
  coverLetterBodyPrefillApplication: Scalars['String']['output']
  coverLetterUpsert: UsersJobsCoverLetterType
  createClientGroup: ClientGroup
  createFeedback: Scalars['String']['output']
  createResume: Resumes
  createResumeFromLinkedinUrl: Resumes
  createResumeUnauthenticated: Resumes
  createUsersJob: UsersJobs
  duplicateResume: Resumes
  editClientGroup: ClientGroup
  exportUsersJobToCsv: Scalars['String']['output']
  fetchJobKeywords: Array<Scalars['String']['output']>
  fetchMissingSkillsOfJobResume: Array<Scalars['String']['output']>
  generateCoverLetter: UsersJobsCoverLetterType
  generateJobAchievement: Array<Scalars['String']['output']>
  generateOneJobAchievement: Scalars['String']['output']
  generateProfessionalSummary: Scalars['String']['output']
  interviewAddMessage: Interview
  interviewStartMock: Interview
  learnSkills: CachedStreamingType
  matchScore: UsersJobsMatch
  optimizeResumeForJob: Resumes
  previewAuthResume: Scalars['String']['output']
  previewResume: Scalars['String']['output']
  questionPrefillApplication: Scalars['String']['output']
  salaryInsights: Scalars['String']['output']
  sampleInterviewQuestions: CachedStreamingType
  scoreResume: ResumesScoreType
  shareJobWithClients: UsersJobs
  subscriptionsRedeemLtdPromoCode: Users
  subscriptionsSessionCreate: Scalars['String']['output']
  subscriptionsSessionFetch: Scalars['String']['output']
  updateArchivedCoverLetter: UsersJobsCoverLetterType
  updateArchivedResume: Resumes
  updateLastPageLoadedAt: Users
  updateResume: Resumes
  updateResumeUnauthenticated: Resumes
  updateShowOriginalDocumentResume: Resumes
  updateTemplateResume: Resumes
  updateUser: Users
  updateUsersJob: UsersJobs
  uploadResume: Resumes
  usedChromeExtension: Scalars['String']['output']
  userEmailVerify: AuthResponse
}

export type MutationAddContactToUsersJobArgs = {
  contact: ContactsInputObject
  id: Scalars['ID']['input']
}

export type MutationAddUsersToClientGroupArgs = {
  clientGroupId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']>
}

export type MutationAssignClientToAdvisorArgs = {
  advisorId: Scalars['ID']['input']
  clientIds: Array<Scalars['ID']['input']>
}

export type MutationAuthPasswordResetRequestArgs = {
  email: Scalars['String']['input']
}

export type MutationAuthProviderAuthenticateArgs = {
  data: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  provider: AuthProviderTypeEnum
  referredByUserUniqueId?: InputMaybe<Scalars['ID']['input']>
  resumeUniqueId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationAuthResetPasswordArgs = {
  newPassword: Scalars['String']['input']
  resetPasswordToken: Scalars['String']['input']
}

export type MutationAuthSignInArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationAuthSignUpArgs = {
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  referredByUserUniqueId?: InputMaybe<Scalars['ID']['input']>
  resumeUniqueId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCoverLetterBodyPrefillApplicationArgs = {
  resumeId: Scalars['ID']['input']
  usersJobId: Scalars['ID']['input']
}

export type MutationCoverLetterUpsertArgs = {
  attributes: UsersJobsCoverLetterInputObject
  usersJobId: Scalars['ID']['input']
}

export type MutationCreateClientGroupArgs = {
  name: Scalars['String']['input']
}

export type MutationCreateFeedbackArgs = {
  feedback: Scalars['String']['input']
  subject?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateResumeArgs = {
  name: Scalars['String']['input']
}

export type MutationCreateResumeFromLinkedinUrlArgs = {
  linkedinProfileUrl: Scalars['String']['input']
}

export type MutationCreateResumeUnauthenticatedArgs = {
  attributes?: InputMaybe<ResumesInputObject>
}

export type MutationCreateUsersJobArgs = {
  applicationInstructions?: InputMaybe<Scalars['String']['input']>
  companyName: Scalars['String']['input']
  contact?: InputMaybe<ContactsInputObject>
  interviewStep?: InputMaybe<UsersJobsInterviewStepEnum>
  isRemote?: InputMaybe<Scalars['Boolean']['input']>
  jobDetails?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  payPeriod?: InputMaybe<UsersJobsPayPeriodEnum>
  position: Scalars['String']['input']
  rejectedStage?: InputMaybe<UsersJobsRejectedStageEnum>
  resumeId?: InputMaybe<Scalars['ID']['input']>
  salaryMax?: InputMaybe<Scalars['Int']['input']>
  salaryMin?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<UsersJobsStatusEnum>
  uniqueUrl?: InputMaybe<Scalars['Boolean']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type MutationDuplicateResumeArgs = {
  id: Scalars['ID']['input']
}

export type MutationEditClientGroupArgs = {
  clientGroupId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type MutationFetchJobKeywordsArgs = {
  usersJobId: Scalars['ID']['input']
}

export type MutationFetchMissingSkillsOfJobResumeArgs = {
  resumeId: Scalars['ID']['input']
  usersJobId: Scalars['ID']['input']
}

export type MutationGenerateCoverLetterArgs = {
  coverLetterLength?: InputMaybe<CoverLetterLengthEnum>
  coverLetterTone?: InputMaybe<CoverLetterToneEnum>
  resumeId: Scalars['ID']['input']
  usersJobId: Scalars['ID']['input']
}

export type MutationGenerateJobAchievementArgs = {
  indices: Array<Scalars['Int']['input']>
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  workPositionId: Scalars['ID']['input']
}

export type MutationGenerateOneJobAchievementArgs = {
  index: Scalars['Int']['input']
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  workPositionId: Scalars['ID']['input']
}

export type MutationGenerateProfessionalSummaryArgs = {
  id: Scalars['ID']['input']
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  useExisting: Scalars['Boolean']['input']
}

export type MutationInterviewAddMessageArgs = {
  interviewId: Scalars['String']['input']
  message: Scalars['String']['input']
}

export type MutationInterviewStartMockArgs = {
  interviewType: InterviewsTypeEnum
  jobDescription?: InputMaybe<Scalars['String']['input']>
  jobTitle: Scalars['String']['input']
  resumeId?: InputMaybe<Scalars['ID']['input']>
  usersJobId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationLearnSkillsArgs = {
  usersJobId: Scalars['ID']['input']
}

export type MutationMatchScoreArgs = {
  resumeId: Scalars['ID']['input']
  usersJobId: Scalars['ID']['input']
}

export type MutationOptimizeResumeForJobArgs = {
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  resumeId: Scalars['ID']['input']
  skills?: InputMaybe<Array<Scalars['String']['input']>>
  usersJobId: Scalars['ID']['input']
}

export type MutationPreviewAuthResumeArgs = {
  id: Scalars['ID']['input']
  template: ResumesTemplateEnum
}

export type MutationPreviewResumeArgs = {
  attributes: ResumesInputObject
  template: ResumesTemplateEnum
}

export type MutationQuestionPrefillApplicationArgs = {
  question: Scalars['String']['input']
  resumeId: Scalars['ID']['input']
}

export type MutationSalaryInsightsArgs = {
  usersJobId: Scalars['ID']['input']
}

export type MutationSampleInterviewQuestionsArgs = {
  usersJobId: Scalars['ID']['input']
}

export type MutationScoreResumeArgs = {
  id: Scalars['ID']['input']
}

export type MutationShareJobWithClientsArgs = {
  clientGroupIds: Array<Scalars['ID']['input']>
  userIds: Array<Scalars['ID']['input']>
  usersJobId: Scalars['ID']['input']
}

export type MutationSubscriptionsRedeemLtdPromoCodeArgs = {
  promoCode: Scalars['String']['input']
}

export type MutationSubscriptionsSessionCreateArgs = {
  plan: SubscriptionPlanEnum
}

export type MutationSubscriptionsSessionFetchArgs = {
  sessionId: Scalars['ID']['input']
}

export type MutationUpdateArchivedCoverLetterArgs = {
  archived: Scalars['Boolean']['input']
  coverLetterId: Scalars['ID']['input']
}

export type MutationUpdateArchivedResumeArgs = {
  archived: Scalars['Boolean']['input']
  id: Scalars['ID']['input']
}

export type MutationUpdateResumeArgs = {
  attributes: ResumesInputObject
  id: Scalars['ID']['input']
}

export type MutationUpdateResumeUnauthenticatedArgs = {
  attributes: ResumesInputObject
  id: Scalars['ID']['input']
}

export type MutationUpdateShowOriginalDocumentResumeArgs = {
  id: Scalars['ID']['input']
  showOriginalDocument: Scalars['Boolean']['input']
}

export type MutationUpdateTemplateResumeArgs = {
  id: Scalars['ID']['input']
  template: ResumesTemplateEnum
  templateColorEnum?: InputMaybe<ResumesTemplateColorEnum>
}

export type MutationUpdateUserArgs = {
  attributes: UsersInputObject
}

export type MutationUpdateUsersJobArgs = {
  applicationInstructions?: InputMaybe<Scalars['String']['input']>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  interviewStep?: InputMaybe<UsersJobsInterviewStepEnum>
  isRemote?: InputMaybe<Scalars['Boolean']['input']>
  jobDetails?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  payPeriod?: InputMaybe<UsersJobsPayPeriodEnum>
  position?: InputMaybe<Scalars['String']['input']>
  rejectedStage?: InputMaybe<UsersJobsRejectedStageEnum>
  resumeId?: InputMaybe<Scalars['ID']['input']>
  salaryMax?: InputMaybe<Scalars['Int']['input']>
  salaryMin?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<UsersJobsStatusEnum>
  url?: InputMaybe<Scalars['String']['input']>
}

export type MutationUploadResumeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  uploadSignedId: Scalars['ID']['input']
}

export type MutationUserEmailVerifyArgs = {
  emailVerificationCode: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type PaginatedAdvisorUsers = {
  __typename?: 'PaginatedAdvisorUsers'
  data: Array<AdvisorUsers>
  pagination: Pagination
}

export type PaginatedClientUsers = {
  __typename?: 'PaginatedClientUsers'
  data: Array<ClientUsers>
  pagination: Pagination
}

export type PaginatedUsersJobs = {
  __typename?: 'PaginatedUsersJobs'
  data: Array<UsersJobs>
  pagination: Pagination
}

export type Pagination = {
  __typename?: 'Pagination'
  page: Scalars['Int']['output']
  pageSize: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
  totalPages: Scalars['Int']['output']
}

export type Query = {
  __typename?: 'Query'
  advisors: PaginatedAdvisorUsers
  client: Users
  clientGroups: Array<ClientGroup>
  clientResumes: Array<Resumes>
  clientUsersJobs: Array<UsersJobs>
  clients: PaginatedClientUsers
  coverLetters: Array<UsersJobsCoverLetterType>
  emailVerifyAllowed?: Maybe<Users>
  findUsersJobByInfo?: Maybe<UsersJobs>
  findUsersJobByUrl?: Maybe<UsersJobs>
  interviews: Array<Interview>
  paginatedUsersJobs: PaginatedUsersJobs
  resetPasswordAllowed: Scalars['Boolean']['output']
  resumeSimpleAnalysis: Array<FieldMessageType>
  resumeUnauthenticated?: Maybe<Resumes>
  resumes: Array<Resumes>
  user: Users
  usersJobs: Array<UsersJobs>
}

export type QueryAdvisorsArgs = {
  fromDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<UsersRoleEnum>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<AdvisorSortEnum>
  sortByDirection?: InputMaybe<SortByDirectionEnum>
  toDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type QueryClientArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryClientGroupsArgs = {
  clientGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryClientResumesArgs = {
  clientId: Scalars['ID']['input']
  resumeIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryClientUsersJobsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  clientId: Scalars['ID']['input']
  partnerCreated?: InputMaybe<Scalars['Boolean']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  usersJobIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryClientsArgs = {
  advisorIds?: InputMaybe<Array<Scalars['ID']['input']>>
  fromDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  toDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type QueryCoverLettersArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  coverLetterIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryEmailVerifyAllowedArgs = {
  emailVerificationCode: Scalars['String']['input']
}

export type QueryFindUsersJobByInfoArgs = {
  companyName: Scalars['String']['input']
  location?: InputMaybe<Scalars['String']['input']>
  position: Scalars['String']['input']
  url?: InputMaybe<Scalars['String']['input']>
}

export type QueryFindUsersJobByUrlArgs = {
  url: Scalars['String']['input']
}

export type QueryInterviewsArgs = {
  interviewIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryPaginatedUsersJobsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<JobPostingSortEnum>
  sortByDirection?: InputMaybe<SortByDirectionEnum>
}

export type QueryResetPasswordAllowedArgs = {
  resetPasswordToken: Scalars['String']['input']
}

export type QueryResumeSimpleAnalysisArgs = {
  id: Scalars['ID']['input']
}

export type QueryResumeUnauthenticatedArgs = {
  id: Scalars['ID']['input']
}

export type QueryResumesArgs = {
  aiGenerated?: InputMaybe<Scalars['Boolean']['input']>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  resumeIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryUsersJobsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  partnerCreated?: InputMaybe<Scalars['Boolean']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  usersJobIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum ResumeDocumentType {
  Doc = 'DOC',
  Pdf = 'PDF',
}

export enum ResumeSortableSections {
  Certifications = 'CERTIFICATIONS',
  Courseworks = 'COURSEWORKS',
  Educations = 'EDUCATIONS',
  Involvements = 'INVOLVEMENTS',
  Projects = 'PROJECTS',
  Skills = 'SKILLS',
  WorkExperiences = 'WORK_EXPERIENCES',
}

export type Resumes = {
  __typename?: 'Resumes'
  aiGenerated: Scalars['Boolean']['output']
  archived: Scalars['Boolean']['output']
  certifications: Array<ResumesCertification>
  courseworks: Array<ResumesCoursework>
  createdAt: Scalars['ISO8601DateTime']['output']
  documentType?: Maybe<ResumeDocumentType>
  documentUrl?: Maybe<Scalars['String']['output']>
  educations: Array<ResumesEducation>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  groupedSkills: Array<ResumesGroupedSkill>
  hideCertifications: Scalars['Boolean']['output']
  hideCourseworks: Scalars['Boolean']['output']
  hideEducations: Scalars['Boolean']['output']
  hideInvolvements: Scalars['Boolean']['output']
  hideProjects: Scalars['Boolean']['output']
  hideSkills: Scalars['Boolean']['output']
  hideSummary: Scalars['Boolean']['output']
  hideWorkExperiences: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  interviewRate?: Maybe<Scalars['Int']['output']>
  involvements: Array<ResumesInvolvement>
  lastName?: Maybe<Scalars['String']['output']>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  mostRecentEmployer?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  professionalSummary?: Maybe<Scalars['String']['output']>
  projects: Array<ResumesProject>
  sectionsOrder: Array<ResumeSortableSections>
  showOriginalDocument: Scalars['Boolean']['output']
  simpleAnalysis: Array<FieldMessageType>
  suggestedSkills: Array<Scalars['String']['output']>
  template: ResumesTemplateEnum
  templateColorEnum?: Maybe<ResumesTemplateColorEnum>
  uniqueId?: Maybe<Scalars['ID']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  website?: Maybe<Scalars['String']['output']>
  workExperiences: Array<ResumesWorkExperience>
}

export type ResumesCertification = {
  __typename?: 'ResumesCertification'
  completionDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  provider?: Maybe<Scalars['String']['output']>
}

export type ResumesCertificationInputObject = {
  completionDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  provider?: InputMaybe<Scalars['String']['input']>
}

export type ResumesCoursework = {
  __typename?: 'ResumesCoursework'
  achievements: Array<Scalars['String']['output']>
  currentlyWorkingOnCourse: Scalars['Boolean']['output']
  endDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  institutionName?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type ResumesCourseworkInputObject = {
  achievements: Array<Scalars['String']['input']>
  currentlyWorkingOnCourse?: InputMaybe<Scalars['Boolean']['input']>
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  institutionName?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type ResumesEducation = {
  __typename?: 'ResumesEducation'
  additionalInformation?: Maybe<Scalars['String']['output']>
  currentlyInEducation: Scalars['Boolean']['output']
  degree?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  gpa?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  institutionName: Scalars['String']['output']
  location?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export type ResumesEducationInputObject = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  currentlyInEducation?: InputMaybe<Scalars['Boolean']['input']>
  degree?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  gpa?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  institutionName: Scalars['String']['input']
  location?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type ResumesGroupedSkill = {
  __typename?: 'ResumesGroupedSkill'
  category?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  skills: Array<Scalars['String']['output']>
}

export type ResumesGroupedSkillInputObject = {
  category?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  skills: Array<Scalars['String']['input']>
}

export type ResumesInputObject = {
  certificationsAttributes: Array<ResumesCertificationInputObject>
  courseworksAttributes: Array<ResumesCourseworkInputObject>
  educationsAttributes: Array<ResumesEducationInputObject>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  groupedSkillsAttributes: Array<ResumesGroupedSkillInputObject>
  hideCertifications?: InputMaybe<Scalars['Boolean']['input']>
  hideCourseworks?: InputMaybe<Scalars['Boolean']['input']>
  hideEducations?: InputMaybe<Scalars['Boolean']['input']>
  hideInvolvements?: InputMaybe<Scalars['Boolean']['input']>
  hideProjects?: InputMaybe<Scalars['Boolean']['input']>
  hideSkills?: InputMaybe<Scalars['Boolean']['input']>
  hideSummary?: InputMaybe<Scalars['Boolean']['input']>
  hideWorkExperiences?: InputMaybe<Scalars['Boolean']['input']>
  involvementsAttributes: Array<ResumesInvolvementInputObject>
  lastName?: InputMaybe<Scalars['String']['input']>
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  professionalSummary?: InputMaybe<Scalars['String']['input']>
  projectsAttributes: Array<ResumesProjectInputObject>
  sectionsOrder?: InputMaybe<Array<ResumeSortableSections>>
  website?: InputMaybe<Scalars['String']['input']>
  workExperiencesAttributes: Array<ResumesWorkExperienceInputObject>
}

export type ResumesInvolvement = {
  __typename?: 'ResumesInvolvement'
  achievements: Array<Scalars['String']['output']>
  currentlyWorkingOnInvolvement: Scalars['Boolean']['output']
  endDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  organizationName?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export type ResumesInvolvementInputObject = {
  achievements: Array<Scalars['String']['input']>
  currentlyWorkingOnInvolvement?: InputMaybe<Scalars['Boolean']['input']>
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  organizationName?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type ResumesProject = {
  __typename?: 'ResumesProject'
  achievements: Array<Scalars['String']['output']>
  currentlyWorkingOnProject: Scalars['Boolean']['output']
  endDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  startDate?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export type ResumesProjectInputObject = {
  achievements: Array<Scalars['String']['input']>
  currentlyWorkingOnProject?: InputMaybe<Scalars['Boolean']['input']>
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type ResumesScoreFieldTagType = {
  __typename?: 'ResumesScoreFieldTagType'
  tagName: Scalars['String']['output']
}

export type ResumesScoreFieldType = {
  __typename?: 'ResumesScoreFieldType'
  didPass: Scalars['Boolean']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  header: Scalars['String']['output']
  tags?: Maybe<Array<ResumesScoreFieldTagType>>
}

export type ResumesScoreType = {
  __typename?: 'ResumesScoreType'
  failed: Scalars['Int']['output']
  passed: Scalars['Int']['output']
  score: Scalars['Int']['output']
  scoreFields: Array<ResumesScoreFieldType>
  total: Scalars['Int']['output']
}

export enum ResumesTemplateColorEnum {
  BauhausLight = 'BAUHAUS_LIGHT',
  HarvardLight = 'HARVARD_LIGHT',
  NeueCyan = 'NEUE_CYAN',
  NeueEmerald = 'NEUE_EMERALD',
  NeueIndigo = 'NEUE_INDIGO',
  NeueLight = 'NEUE_LIGHT',
  OxfordCyan = 'OXFORD_CYAN',
  OxfordEmerald = 'OXFORD_EMERALD',
  OxfordIndigo = 'OXFORD_INDIGO',
  OxfordLight = 'OXFORD_LIGHT',
}

export enum ResumesTemplateEnum {
  Bauhaus = 'BAUHAUS',
  Harvard = 'HARVARD',
  Neue = 'NEUE',
  Oxford = 'OXFORD',
}

export type ResumesWorkExperience = {
  __typename?: 'ResumesWorkExperience'
  companyDescription?: Maybe<Scalars['String']['output']>
  companyName: Scalars['String']['output']
  id: Scalars['ID']['output']
  simpleAnalysis: Array<FieldMessageType>
  workPositions: Array<ResumesWorkPosition>
}

export type ResumesWorkExperienceInputObject = {
  companyDescription?: InputMaybe<Scalars['String']['input']>
  companyName: Scalars['String']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  workPositionsAttributes: Array<ResumesWorkPositionInputObject>
}

export type ResumesWorkPosition = {
  __typename?: 'ResumesWorkPosition'
  achievements: Array<Scalars['String']['output']>
  currentlyInPosition: Scalars['Boolean']['output']
  endDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  location?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  simpleAnalysis: Array<FieldMessageType>
  startDate?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export type ResumesWorkPositionInputObject = {
  achievements: Array<Scalars['String']['input']>
  currentlyInPosition?: InputMaybe<Scalars['Boolean']['input']>
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

/** Autogenerated return type of SampleInterviewQuestions. */
export type SampleInterviewQuestionsPayload = {
  __typename?: 'SampleInterviewQuestionsPayload'
  content: Scalars['String']['output']
  i?: Maybe<Scalars['Int']['output']>
  initial: Scalars['Boolean']['output']
  requestId: Scalars['String']['output']
  usersJobId: Scalars['ID']['output']
}

export enum SortByDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** The subscription root for the GraphQL schema */
export type Subscription = {
  __typename?: 'Subscription'
  learnSkills: LearnSkillsPayload
  sampleInterviewQuestions: SampleInterviewQuestionsPayload
}

export enum SubscriptionPlanEnum {
  Free = 'FREE',
  Pro = 'PRO',
  ProQuarterly = 'PRO_QUARTERLY',
}

export type Users = {
  __typename?: 'Users'
  activeResumes?: Maybe<Array<Resumes>>
  advisor?: Maybe<Users>
  completedAllChecklistItems: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  hasSubscription: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isAdvisoryClient: Scalars['Boolean']['output']
  isAdvisoryOrgAccount: Scalars['Boolean']['output']
  lastActiveAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  lastJobUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  numClients: Scalars['Int']['output']
  numJobs: Scalars['Int']['output']
  numOwnClients: Scalars['Int']['output']
  numStartedInterviews: Scalars['Int']['output']
  numUploadedResumes: Scalars['Int']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  plan: SubscriptionPlanEnum
  referralTokens: Scalars['Int']['output']
  role: UsersRoleEnum
  tokenAchievements: Scalars['Int']['output']
  tokenCoverLetter: Scalars['Int']['output']
  tokenInterview: Scalars['Int']['output']
  tokenInterviewQuestions: Scalars['Int']['output']
  tokenJobMatch: Scalars['Int']['output']
  tokenLearnSkills: Scalars['Int']['output']
  tokenProfessionalSummary: Scalars['Int']['output']
  tokenSalaryInsights: Scalars['Int']['output']
  uniqueId: Scalars['String']['output']
  usedChromeExtension: Scalars['Boolean']['output']
  usedJobAiFeature: Scalars['Boolean']['output']
  usedResumeAiFeature: Scalars['Boolean']['output']
  usersJobs: Array<UsersJobs>
  verified: Scalars['Boolean']['output']
  website?: Maybe<Scalars['String']['output']>
}

export type UsersInputObject = {
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type UsersJobs = {
  __typename?: 'UsersJobs'
  applicationInstructions?: Maybe<Scalars['String']['output']>
  appliedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  archived: Scalars['Boolean']['output']
  clientsShared: Array<Users>
  companyName: Scalars['String']['output']
  contacts?: Maybe<Array<Contacts>>
  coverLetter?: Maybe<UsersJobsCoverLetterType>
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  interviewStep?: Maybe<UsersJobsInterviewStepEnum>
  interviewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  isRemote?: Maybe<Scalars['Boolean']['output']>
  jobDetails?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  numClientsApplied: Scalars['Int']['output']
  numClientsShared: Scalars['Int']['output']
  offerAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  partnerArchived: Scalars['Boolean']['output']
  partnerCreatedByUser?: Maybe<Users>
  payPeriod: UsersJobsPayPeriodEnum
  position: Scalars['String']['output']
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  rejectedStage?: Maybe<UsersJobsRejectedStageEnum>
  resume?: Maybe<Resumes>
  salaryMax?: Maybe<Scalars['Int']['output']>
  salaryMin?: Maybe<Scalars['Int']['output']>
  status: UsersJobsStatusEnum
  updatedAt: Scalars['ISO8601DateTime']['output']
  url?: Maybe<Scalars['String']['output']>
}

export type UsersJobsCoverLetterInputObject = {
  body?: InputMaybe<Scalars['String']['input']>
  candidateAddress?: InputMaybe<Scalars['String']['input']>
  companyLocation?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  footer?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type UsersJobsCoverLetterType = {
  __typename?: 'UsersJobsCoverLetterType'
  archived: Scalars['Boolean']['output']
  body?: Maybe<Scalars['String']['output']>
  candidateAddress?: Maybe<Scalars['String']['output']>
  companyLocation?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  footer?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  mostRecentUsedResumeForGenerate?: Maybe<Resumes>
  phoneNumber?: Maybe<Scalars['String']['output']>
  usersJob: UsersJobs
}

export enum UsersJobsInterviewStepEnum {
  IntroScreen = 'INTRO_SCREEN',
  Onsite = 'ONSITE',
  PhoneScreen = 'PHONE_SCREEN',
}

export type UsersJobsMatch = {
  __typename?: 'UsersJobsMatch'
  hardSkills: Array<Scalars['String']['output']>
  matchLevel: UsersJobsMatchEnum
  matches: Array<FieldMessageType>
}

export enum UsersJobsMatchEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export enum UsersJobsPayPeriodEnum {
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY',
}

export enum UsersJobsRejectedStageEnum {
  Application = 'APPLICATION',
  Interview = 'INTERVIEW',
  Reference = 'REFERENCE',
}

export enum UsersJobsStatusEnum {
  Applied = 'APPLIED',
  Interview = 'INTERVIEW',
  Offer = 'OFFER',
  Rejected = 'REJECTED',
  Wishlist = 'WISHLIST',
}

export enum UsersRoleEnum {
  AccountManager = 'ACCOUNT_MANAGER',
  Admin = 'ADMIN',
  Advisor = 'ADVISOR',
  Jobseeker = 'JOBSEEKER',
}

export type AdvisorsList_AdvisorsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  fromDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  toDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  role?: InputMaybe<UsersRoleEnum>
  sortBy?: InputMaybe<AdvisorSortEnum>
  sortByDirection?: InputMaybe<SortByDirectionEnum>
}>

export type AdvisorsList_AdvisorsQuery = {
  __typename?: 'Query'
  advisors: {
    __typename?: 'PaginatedAdvisorUsers'
    pagination: { __typename?: 'Pagination'; page: number; totalPages: number }
    data: Array<{
      __typename?: 'AdvisorUsers'
      id: string
      jobsShared: Array<{
        __typename?: 'UsersJobs'
        id: string
        applicationInstructions?: string | null
        createdAt: any
        companyName: string
        isRemote?: boolean | null
        jobDetails?: string | null
        location?: string | null
        notes?: string | null
        payPeriod: UsersJobsPayPeriodEnum
        position: string
        salaryMax?: number | null
        salaryMin?: number | null
        status: UsersJobsStatusEnum
        updatedAt: any
        url?: string | null
        archived: boolean
        rejectedStage?: UsersJobsRejectedStageEnum | null
        interviewStep?: UsersJobsInterviewStepEnum | null
        numClientsApplied: number
        numClientsShared: number
        partnerArchived: boolean
        partnerCreatedByUser?: {
          __typename?: 'Users'
          id: string
          firstName?: string | null
          lastName?: string | null
        } | null
        contacts?: Array<{
          __typename?: 'Contacts'
          id: string
          email?: string | null
          name?: string | null
        }> | null
        resume?: { __typename?: 'Resumes'; id: string; name: string } | null
      }>
      jobsAdded: Array<{
        __typename?: 'UsersJobs'
        id: string
        applicationInstructions?: string | null
        createdAt: any
        companyName: string
        isRemote?: boolean | null
        jobDetails?: string | null
        location?: string | null
        notes?: string | null
        payPeriod: UsersJobsPayPeriodEnum
        position: string
        salaryMax?: number | null
        salaryMin?: number | null
        status: UsersJobsStatusEnum
        updatedAt: any
        url?: string | null
        archived: boolean
        rejectedStage?: UsersJobsRejectedStageEnum | null
        interviewStep?: UsersJobsInterviewStepEnum | null
        numClientsApplied: number
        numClientsShared: number
        partnerArchived: boolean
        partnerCreatedByUser?: {
          __typename?: 'Users'
          id: string
          firstName?: string | null
          lastName?: string | null
        } | null
        contacts?: Array<{
          __typename?: 'Contacts'
          id: string
          email?: string | null
          name?: string | null
        }> | null
        resume?: { __typename?: 'Resumes'; id: string; name: string } | null
      }>
      user: {
        __typename?: 'Users'
        id: string
        numOwnClients: number
        uniqueId: string
        role: UsersRoleEnum
        email: string
        firstName?: string | null
        lastName?: string | null
        plan: SubscriptionPlanEnum
        tokenCoverLetter: number
        tokenInterviewQuestions: number
        tokenJobMatch: number
        tokenProfessionalSummary: number
        tokenSalaryInsights: number
        tokenLearnSkills: number
        tokenAchievements: number
        tokenInterview: number
        usedChromeExtension: boolean
        numUploadedResumes: number
        numStartedInterviews: number
        referralTokens: number
        hasSubscription: boolean
        isAdvisoryClient: boolean
        isAdvisoryOrgAccount: boolean
      }
    }>
  }
}

export type ClientGroupClientContent_ClientGroupsQueryVariables = Exact<{
  clientGroupId: Scalars['ID']['input']
}>

export type ClientGroupClientContent_ClientGroupsQuery = {
  __typename?: 'Query'
  clientGroups: Array<{ __typename?: 'ClientGroup'; id: string; name: string }>
}

export type ClientGroupClientList_ClientGroupsQueryVariables = Exact<{
  clientGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type ClientGroupClientList_ClientGroupsQuery = {
  __typename?: 'Query'
  clientGroups: Array<{
    __typename?: 'ClientGroup'
    id: string
    name: string
    clients: Array<{
      __typename?: 'ClientUsers'
      id: string
      numAllJobs: number
      numInterviewedJobs: number
      numOfferedJobs: number
      numRejectedJobs: number
      numAppliedJobs: number
      user: {
        __typename?: 'Users'
        firstName?: string | null
        lastName?: string | null
        lastActiveAt?: any | null
        advisor?: {
          __typename?: 'Users'
          firstName?: string | null
          lastName?: string | null
        } | null
      }
    }>
  }>
}

export type ClientBoard_ClientUsersJobsQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
  clientId: Scalars['ID']['input']
}>

export type ClientBoard_ClientUsersJobsQuery = {
  __typename?: 'Query'
  clientUsersJobs: Array<{
    __typename?: 'UsersJobs'
    id: string
    applicationInstructions?: string | null
    createdAt: any
    companyName: string
    isRemote?: boolean | null
    jobDetails?: string | null
    location?: string | null
    notes?: string | null
    payPeriod: UsersJobsPayPeriodEnum
    position: string
    salaryMax?: number | null
    salaryMin?: number | null
    status: UsersJobsStatusEnum
    updatedAt: any
    url?: string | null
    archived: boolean
    rejectedStage?: UsersJobsRejectedStageEnum | null
    interviewStep?: UsersJobsInterviewStepEnum | null
    numClientsApplied: number
    numClientsShared: number
    partnerArchived: boolean
    partnerCreatedByUser?: {
      __typename?: 'Users'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    contacts?: Array<{
      __typename?: 'Contacts'
      id: string
      email?: string | null
      name?: string | null
    }> | null
    resume?: { __typename?: 'Resumes'; id: string; name: string } | null
  }>
}

export type ClientContent_ClientQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type ClientContent_ClientQuery = {
  __typename?: 'Query'
  client: {
    __typename?: 'Users'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    lastActiveAt?: any | null
    usersJobs: Array<{ __typename?: 'UsersJobs'; id: string; archived: boolean }>
    activeResumes?: Array<{ __typename?: 'Resumes'; id: string }> | null
  }
}

export type ClientResumes_ClientQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type ClientResumes_ClientQuery = {
  __typename?: 'Query'
  client: {
    __typename?: 'Users'
    id: string
    activeResumes?: Array<{
      __typename?: 'Resumes'
      id: string
      name: string
      createdAt: any
      updatedAt: any
    }> | null
  }
}

export type ClientsList_ClientsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  fromDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  toDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  advisorIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type ClientsList_ClientsQuery = {
  __typename?: 'Query'
  clients: {
    __typename?: 'PaginatedClientUsers'
    pagination: { __typename?: 'Pagination'; page: number; totalPages: number }
    data: Array<{
      __typename?: 'ClientUsers'
      id: string
      numAllJobs: number
      numAppliedJobs: number
      numInterviewedJobs: number
      numOfferedJobs: number
      numRejectedJobs: number
      user: {
        __typename?: 'Users'
        id: string
        firstName?: string | null
        lastName?: string | null
        lastActiveAt?: any | null
        advisor?: {
          __typename?: 'Users'
          id: string
          firstName?: string | null
          lastName?: string | null
        } | null
      }
    }>
  }
}

export type JobPostingsList_JobPostingsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  sortBy?: InputMaybe<JobPostingSortEnum>
  sortByDirection?: InputMaybe<SortByDirectionEnum>
}>

export type JobPostingsList_JobPostingsQuery = {
  __typename?: 'Query'
  paginatedUsersJobs: {
    __typename?: 'PaginatedUsersJobs'
    pagination: { __typename?: 'Pagination'; page: number; totalPages: number }
    data: Array<{
      __typename?: 'UsersJobs'
      id: string
      archived: boolean
      companyName: string
      createdAt: any
      numClientsApplied: number
      numClientsShared: number
      position: string
      location?: string | null
      isRemote?: boolean | null
      url?: string | null
      payPeriod: UsersJobsPayPeriodEnum
      salaryMin?: number | null
      salaryMax?: number | null
      clientsShared: Array<{
        __typename?: 'Users'
        id: string
        firstName?: string | null
        lastName?: string | null
      }>
    }>
  }
}

export type JobPostingsList_UpdateUsersJobArchivedMutationVariables = Exact<{
  id: Scalars['ID']['input']
  archived?: InputMaybe<Scalars['Boolean']['input']>
}>

export type JobPostingsList_UpdateUsersJobArchivedMutation = {
  __typename?: 'Mutation'
  updateUsersJob: { __typename?: 'UsersJobs'; id: string; archived: boolean }
}

export type UserJobsActivityFragment = {
  __typename?: 'UsersJobs'
  id: string
  interviewStep?: UsersJobsInterviewStepEnum | null
  interviewedAt?: any | null
  createdAt: any
  appliedAt?: any | null
  offerAt?: any | null
  rejectedAt?: any | null
  rejectedStage?: UsersJobsRejectedStageEnum | null
}

export type LearnSkills_LearnSkillsMutationVariables = Exact<{
  usersJobId: Scalars['ID']['input']
}>

export type LearnSkills_LearnSkillsMutation = {
  __typename?: 'Mutation'
  learnSkills: { __typename?: 'CachedStreamingType'; result: string; isSync: boolean }
}

export type LearnSkillsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>

export type LearnSkillsSubscriptionSubscription = {
  __typename?: 'Subscription'
  learnSkills: {
    __typename?: 'LearnSkillsPayload'
    content: string
    usersJobId: string
    initial: boolean
    requestId: string
    i?: number | null
  }
}

export type MatchScore_ResumesQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
}>

export type MatchScore_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{
    __typename?: 'Resumes'
    id: string
    name: string
    groupedSkills: Array<{ __typename?: 'ResumesGroupedSkill'; skills: Array<string> }>
  }>
}

export type MatchScore_MatchScoreMutationVariables = Exact<{
  usersJobId: Scalars['ID']['input']
  resumeId: Scalars['ID']['input']
}>

export type MatchScore_MatchScoreMutation = {
  __typename?: 'Mutation'
  matchScore: {
    __typename?: 'UsersJobsMatch'
    matchLevel: UsersJobsMatchEnum
    hardSkills: Array<string>
    matches: Array<{ __typename?: 'FieldMessageType'; field: string; errorMessage?: string | null }>
  }
}

export type SalaryInsights_SalaryInsightsMutationVariables = Exact<{
  usersJobId: Scalars['ID']['input']
}>

export type SalaryInsights_SalaryInsightsMutation = {
  __typename?: 'Mutation'
  salaryInsights: string
}

export type ApplicationContent_UsersJobsQueryVariables = Exact<{
  usersJobIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type ApplicationContent_UsersJobsQuery = {
  __typename?: 'Query'
  usersJobs: Array<{
    __typename?: 'UsersJobs'
    id: string
    interviewStep?: UsersJobsInterviewStepEnum | null
    interviewedAt?: any | null
    createdAt: any
    appliedAt?: any | null
    offerAt?: any | null
    rejectedAt?: any | null
    rejectedStage?: UsersJobsRejectedStageEnum | null
    applicationInstructions?: string | null
    companyName: string
    isRemote?: boolean | null
    jobDetails?: string | null
    location?: string | null
    notes?: string | null
    payPeriod: UsersJobsPayPeriodEnum
    position: string
    salaryMax?: number | null
    salaryMin?: number | null
    status: UsersJobsStatusEnum
    updatedAt: any
    url?: string | null
    archived: boolean
    numClientsApplied: number
    numClientsShared: number
    partnerArchived: boolean
    partnerCreatedByUser?: {
      __typename?: 'Users'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    contacts?: Array<{
      __typename?: 'Contacts'
      id: string
      email?: string | null
      name?: string | null
    }> | null
    resume?: { __typename?: 'Resumes'; id: string; name: string } | null
  }>
  user: {
    __typename?: 'Users'
    id: string
    tokenSalaryInsights: number
    tokenJobMatch: number
    tokenLearnSkills: number
    tokenAchievements: number
    plan: SubscriptionPlanEnum
  }
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type ApplicationContent_UpdateUsersJobMutationVariables = Exact<{
  applicationInstructions?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  isRemote?: InputMaybe<Scalars['Boolean']['input']>
  jobDetails?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  payPeriod?: InputMaybe<UsersJobsPayPeriodEnum>
  position?: InputMaybe<Scalars['String']['input']>
  salaryMax?: InputMaybe<Scalars['Int']['input']>
  salaryMin?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<UsersJobsStatusEnum>
  rejectedStage?: InputMaybe<UsersJobsRejectedStageEnum>
  interviewStep?: InputMaybe<UsersJobsInterviewStepEnum>
  url?: InputMaybe<Scalars['String']['input']>
  resumeId?: InputMaybe<Scalars['ID']['input']>
}>

export type ApplicationContent_UpdateUsersJobMutation = {
  __typename?: 'Mutation'
  updateUsersJob: {
    __typename?: 'UsersJobs'
    id: string
    interviewStep?: UsersJobsInterviewStepEnum | null
    interviewedAt?: any | null
    createdAt: any
    appliedAt?: any | null
    offerAt?: any | null
    rejectedAt?: any | null
    rejectedStage?: UsersJobsRejectedStageEnum | null
    applicationInstructions?: string | null
    companyName: string
    isRemote?: boolean | null
    jobDetails?: string | null
    location?: string | null
    notes?: string | null
    payPeriod: UsersJobsPayPeriodEnum
    position: string
    salaryMax?: number | null
    salaryMin?: number | null
    status: UsersJobsStatusEnum
    updatedAt: any
    url?: string | null
    archived: boolean
    numClientsApplied: number
    numClientsShared: number
    partnerArchived: boolean
    partnerCreatedByUser?: {
      __typename?: 'Users'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    contacts?: Array<{
      __typename?: 'Contacts'
      id: string
      email?: string | null
      name?: string | null
    }> | null
    resume?: { __typename?: 'Resumes'; id: string; name: string } | null
  }
}

export type Applications_UsersJobsQueryVariables = Exact<{
  archived: Scalars['Boolean']['input']
}>

export type Applications_UsersJobsQuery = {
  __typename?: 'Query'
  usersJobs: Array<{
    __typename?: 'UsersJobs'
    id: string
    applicationInstructions?: string | null
    createdAt: any
    companyName: string
    isRemote?: boolean | null
    jobDetails?: string | null
    location?: string | null
    notes?: string | null
    payPeriod: UsersJobsPayPeriodEnum
    position: string
    salaryMax?: number | null
    salaryMin?: number | null
    status: UsersJobsStatusEnum
    updatedAt: any
    url?: string | null
    archived: boolean
    rejectedStage?: UsersJobsRejectedStageEnum | null
    interviewStep?: UsersJobsInterviewStepEnum | null
    numClientsApplied: number
    numClientsShared: number
    partnerArchived: boolean
    partnerCreatedByUser?: {
      __typename?: 'Users'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    contacts?: Array<{
      __typename?: 'Contacts'
      id: string
      email?: string | null
      name?: string | null
    }> | null
    resume?: { __typename?: 'Resumes'; id: string; name: string } | null
  }>
}

export type Applications_UpdateUsersJobStatusMutationVariables = Exact<{
  id: Scalars['ID']['input']
  status: UsersJobsStatusEnum
}>

export type Applications_UpdateUsersJobStatusMutation = {
  __typename?: 'Mutation'
  updateUsersJob: {
    __typename: 'UsersJobs'
    id: string
    applicationInstructions?: string | null
    createdAt: any
    companyName: string
    isRemote?: boolean | null
    jobDetails?: string | null
    location?: string | null
    notes?: string | null
    payPeriod: UsersJobsPayPeriodEnum
    position: string
    salaryMax?: number | null
    salaryMin?: number | null
    status: UsersJobsStatusEnum
    updatedAt: any
    url?: string | null
    archived: boolean
    rejectedStage?: UsersJobsRejectedStageEnum | null
    interviewStep?: UsersJobsInterviewStepEnum | null
    numClientsApplied: number
    numClientsShared: number
    partnerArchived: boolean
    partnerCreatedByUser?: {
      __typename?: 'Users'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    contacts?: Array<{
      __typename?: 'Contacts'
      id: string
      email?: string | null
      name?: string | null
    }> | null
    resume?: { __typename?: 'Resumes'; id: string; name: string } | null
  }
}

export type Applications_UpdateUsersJobMutationVariables = Exact<{
  id: Scalars['ID']['input']
  archived?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<UsersJobsStatusEnum>
}>

export type Applications_UpdateUsersJobMutation = {
  __typename?: 'Mutation'
  updateUsersJob: {
    __typename: 'UsersJobs'
    id: string
    applicationInstructions?: string | null
    createdAt: any
    companyName: string
    isRemote?: boolean | null
    jobDetails?: string | null
    location?: string | null
    notes?: string | null
    payPeriod: UsersJobsPayPeriodEnum
    position: string
    salaryMax?: number | null
    salaryMin?: number | null
    status: UsersJobsStatusEnum
    updatedAt: any
    url?: string | null
    archived: boolean
    rejectedStage?: UsersJobsRejectedStageEnum | null
    interviewStep?: UsersJobsInterviewStepEnum | null
    numClientsApplied: number
    numClientsShared: number
    partnerArchived: boolean
    partnerCreatedByUser?: {
      __typename?: 'Users'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    contacts?: Array<{
      __typename?: 'Contacts'
      id: string
      email?: string | null
      name?: string | null
    }> | null
    resume?: { __typename?: 'Resumes'; id: string; name: string } | null
  }
}

export type CoverLetterFragment = {
  __typename?: 'UsersJobsCoverLetterType'
  id: string
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  candidateAddress?: string | null
  companyName?: string | null
  companyLocation?: string | null
  phoneNumber?: string | null
  body?: string | null
  footer?: string | null
  archived: boolean
  mostRecentUsedResumeForGenerate?: { __typename?: 'Resumes'; id: string } | null
}

export type CoverLetterContent_CoverLettersQueryVariables = Exact<{
  coverLetterId: Scalars['ID']['input']
}>

export type CoverLetterContent_CoverLettersQuery = {
  __typename?: 'Query'
  coverLetters: Array<{
    __typename?: 'UsersJobsCoverLetterType'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    candidateAddress?: string | null
    companyName?: string | null
    companyLocation?: string | null
    phoneNumber?: string | null
    body?: string | null
    footer?: string | null
    archived: boolean
    usersJob: {
      __typename?: 'UsersJobs'
      id: string
      applicationInstructions?: string | null
      createdAt: any
      companyName: string
      isRemote?: boolean | null
      jobDetails?: string | null
      location?: string | null
      notes?: string | null
      payPeriod: UsersJobsPayPeriodEnum
      position: string
      salaryMax?: number | null
      salaryMin?: number | null
      status: UsersJobsStatusEnum
      updatedAt: any
      url?: string | null
      archived: boolean
      rejectedStage?: UsersJobsRejectedStageEnum | null
      interviewStep?: UsersJobsInterviewStepEnum | null
      numClientsApplied: number
      numClientsShared: number
      partnerArchived: boolean
      partnerCreatedByUser?: {
        __typename?: 'Users'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
      contacts?: Array<{
        __typename?: 'Contacts'
        id: string
        email?: string | null
        name?: string | null
      }> | null
      resume?: { __typename?: 'Resumes'; id: string; name: string } | null
    }
    mostRecentUsedResumeForGenerate?: { __typename?: 'Resumes'; id: string } | null
  }>
}

export type CoverLettercontent_ResumesQueryVariables = Exact<{ [key: string]: never }>

export type CoverLettercontent_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type CoverLetterContent_GenerateCoverLetterMutationVariables = Exact<{
  coverLetterLength?: InputMaybe<CoverLetterLengthEnum>
  coverLetterTone?: InputMaybe<CoverLetterToneEnum>
  resumeId: Scalars['ID']['input']
  usersJobId: Scalars['ID']['input']
}>

export type CoverLetterContent_GenerateCoverLetterMutation = {
  __typename?: 'Mutation'
  generateCoverLetter: { __typename?: 'UsersJobsCoverLetterType'; id: string; body?: string | null }
}

export type CoverLetterContent_CoverLetterUpsertMutationVariables = Exact<{
  usersJobId: Scalars['ID']['input']
  attributes: UsersJobsCoverLetterInputObject
}>

export type CoverLetterContent_CoverLetterUpsertMutation = {
  __typename?: 'Mutation'
  coverLetterUpsert: {
    __typename?: 'UsersJobsCoverLetterType'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    candidateAddress?: string | null
    companyName?: string | null
    companyLocation?: string | null
    phoneNumber?: string | null
    body?: string | null
    footer?: string | null
    archived: boolean
    mostRecentUsedResumeForGenerate?: { __typename?: 'Resumes'; id: string } | null
  }
}

export type CoverLettersList_CoverLettersQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CoverLettersList_CoverLettersQuery = {
  __typename?: 'Query'
  coverLetters: Array<{
    __typename?: 'UsersJobsCoverLetterType'
    id: string
    body?: string | null
    createdAt: any
    usersJob: { __typename?: 'UsersJobs'; id: string; companyName: string; position: string }
  }>
}

export type DashboardContent_ItemsDataQueryVariables = Exact<{ [key: string]: never }>

export type DashboardContent_ItemsDataQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'Users'
    id: string
    numJobs: number
    usedJobAiFeature: boolean
    usedResumeAiFeature: boolean
    usedChromeExtension: boolean
  }
  resumes: Array<{
    __typename?: 'Resumes'
    id: string
    simpleAnalysis: Array<{ __typename?: 'FieldMessageType'; errorMessage?: string | null }>
    workExperiences: Array<{
      __typename?: 'ResumesWorkExperience'
      simpleAnalysis: Array<{ __typename?: 'FieldMessageType'; errorMessage?: string | null }>
      workPositions: Array<{
        __typename?: 'ResumesWorkPosition'
        simpleAnalysis: Array<{ __typename?: 'FieldMessageType'; errorMessage?: string | null }>
      }>
    }>
  }>
}

export type Interview_GetInterviewQueryVariables = Exact<{
  interviewId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type Interview_GetInterviewQuery = {
  __typename?: 'Query'
  interviews: Array<{
    __typename?: 'Interview'
    id: string
    status: InterviewsStatusEnum
    interviewType: InterviewsTypeEnum
    score?: number | null
    jobTitle: string
    messages: Array<{ __typename?: 'InterviewsMessage'; fromUser: boolean; message: string }>
  }>
}

export type InterviewsSidebar_InterviewsQueryVariables = Exact<{ [key: string]: never }>

export type InterviewsSidebar_InterviewsQuery = {
  __typename?: 'Query'
  interviews: Array<{
    __typename?: 'Interview'
    id: string
    status: InterviewsStatusEnum
    interviewType: InterviewsTypeEnum
    score?: number | null
    jobTitle: string
  }>
}

export type Interviews_InterviewFragment = {
  __typename?: 'Interview'
  id: string
  status: InterviewsStatusEnum
  interviewType: InterviewsTypeEnum
  score?: number | null
  jobTitle: string
  messages: Array<{ __typename?: 'InterviewsMessage'; fromUser: boolean; message: string }>
}

export type Interviews_InterviewsQueryVariables = Exact<{ [key: string]: never }>

export type Interviews_InterviewsQuery = {
  __typename?: 'Query'
  interviews: Array<{
    __typename?: 'Interview'
    id: string
    status: InterviewsStatusEnum
    interviewType: InterviewsTypeEnum
    score?: number | null
    jobTitle: string
  }>
}

export type Interviews_InterviewAddMessageMutationVariables = Exact<{
  interviewId: Scalars['String']['input']
  message: Scalars['String']['input']
}>

export type Interviews_InterviewAddMessageMutation = {
  __typename?: 'Mutation'
  interviewAddMessage: {
    __typename?: 'Interview'
    id: string
    status: InterviewsStatusEnum
    interviewType: InterviewsTypeEnum
    score?: number | null
    jobTitle: string
    messages: Array<{ __typename?: 'InterviewsMessage'; fromUser: boolean; message: string }>
  }
}

export type ListEmpty_CreateResumeMutationVariables = Exact<{
  name: Scalars['String']['input']
}>

export type ListEmpty_CreateResumeMutation = {
  __typename?: 'Mutation'
  createResume: { __typename?: 'Resumes'; id: string; name: string }
}

export type ResumesList_ResumesFragment = {
  __typename?: 'Resumes'
  id: string
  name: string
  archived: boolean
  createdAt: any
  updatedAt: any
  aiGenerated: boolean
}

export type ResumesList_AllResumesQueryVariables = Exact<{ [key: string]: never }>

export type ResumesList_AllResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type ResumesList_ResumesQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
  aiGenerated?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ResumesList_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{
    __typename?: 'Resumes'
    id: string
    name: string
    archived: boolean
    createdAt: any
    updatedAt: any
    aiGenerated: boolean
  }>
}

export type ResumeList_UpdateArchivedResumeMutationVariables = Exact<{
  id: Scalars['ID']['input']
  archived: Scalars['Boolean']['input']
}>

export type ResumeList_UpdateArchivedResumeMutation = {
  __typename?: 'Mutation'
  updateArchivedResume: {
    __typename?: 'Resumes'
    id: string
    name: string
    archived: boolean
    createdAt: any
    updatedAt: any
    aiGenerated: boolean
  }
}

export type ResumeList_DuplicateResumeMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ResumeList_DuplicateResumeMutation = {
  __typename?: 'Mutation'
  duplicateResume: {
    __typename?: 'Resumes'
    id: string
    name: string
    archived: boolean
    createdAt: any
    updatedAt: any
    aiGenerated: boolean
  }
}

export type ForgotPassword_AuthPasswordResetRequestMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type ForgotPassword_AuthPasswordResetRequestMutation = {
  __typename?: 'Mutation'
  authPasswordResetRequest: string
}

export type ResetPassword_ResetPasswordAllowedQueryVariables = Exact<{
  resetPasswordToken: Scalars['String']['input']
}>

export type ResetPassword_ResetPasswordAllowedQuery = {
  __typename?: 'Query'
  resetPasswordAllowed: boolean
}

export type ResetPassword_ResetPasswordMutationVariables = Exact<{
  resetPasswordToken: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}>

export type ResetPassword_ResetPasswordMutation = {
  __typename?: 'Mutation'
  authResetPassword: string
}

export type EmailVerify_EmailVerifyAllowedQueryVariables = Exact<{
  emailVerificationCode: Scalars['String']['input']
}>

export type EmailVerify_EmailVerifyAllowedQuery = {
  __typename?: 'Query'
  emailVerifyAllowed?: {
    __typename?: 'Users'
    firstName?: string | null
    lastName?: string | null
    email: string
  } | null
}

export type ApplicationSelect_UsersJobsFragment = {
  __typename?: 'UsersJobs'
  id: string
  companyName: string
  position: string
  jobDetails?: string | null
}

export type ApplicationSelect_UsersJobsQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ApplicationSelect_UsersJobsQuery = {
  __typename?: 'Query'
  usersJobs: Array<{
    __typename?: 'UsersJobs'
    id: string
    companyName: string
    position: string
    jobDetails?: string | null
  }>
}

export type Feedback_FeedbackCreateMutationVariables = Exact<{
  subject?: InputMaybe<Scalars['String']['input']>
  feedback: Scalars['String']['input']
}>

export type Feedback_FeedbackCreateMutation = { __typename?: 'Mutation'; createFeedback: string }

export type Navigation_ItemsDataQueryVariables = Exact<{ [key: string]: never }>

export type Navigation_ItemsDataQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'Users'
    id: string
    numJobs: number
    usedJobAiFeature: boolean
    usedResumeAiFeature: boolean
    usedChromeExtension: boolean
  }
  resumes: Array<{
    __typename?: 'Resumes'
    id: string
    simpleAnalysis: Array<{ __typename?: 'FieldMessageType'; errorMessage?: string | null }>
    workExperiences: Array<{
      __typename?: 'ResumesWorkExperience'
      simpleAnalysis: Array<{ __typename?: 'FieldMessageType'; errorMessage?: string | null }>
      workPositions: Array<{
        __typename?: 'ResumesWorkPosition'
        simpleAnalysis: Array<{ __typename?: 'FieldMessageType'; errorMessage?: string | null }>
      }>
    }>
  }>
}

export type Navigation_ClientGroupsQueryVariables = Exact<{ [key: string]: never }>

export type Navigation_ClientGroupsQuery = {
  __typename?: 'Query'
  clientGroups: Array<{ __typename?: 'ClientGroup'; id: string; name: string }>
}

export type ResumesSelect_ResumesQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
  optimized?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ResumesSelect_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type UpdateLastPageLoaded_UpdateLastPageLoadedAtMutationVariables = Exact<{
  [key: string]: never
}>

export type UpdateLastPageLoaded_UpdateLastPageLoadedAtMutation = {
  __typename?: 'Mutation'
  updateLastPageLoadedAt: { __typename?: 'Users'; id: string }
}

export type UsersUpdateFragment = {
  __typename?: 'Users'
  id: string
  firstName?: string | null
  lastName?: string | null
  phoneNumber?: string | null
  location?: string | null
  website?: string | null
  linkedinUrl?: string | null
}

export type AccountSettingsDialog_UserQueryVariables = Exact<{ [key: string]: never }>

export type AccountSettingsDialog_UserQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'Users'
    id: string
    firstName?: string | null
    lastName?: string | null
    phoneNumber?: string | null
    location?: string | null
    website?: string | null
    linkedinUrl?: string | null
  }
}

export type AccountSettingsDialog_UpdateUserMutationVariables = Exact<{
  attributes: UsersInputObject
}>

export type AccountSettingsDialog_UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'Users'
    id: string
    firstName?: string | null
    lastName?: string | null
    phoneNumber?: string | null
    location?: string | null
    website?: string | null
    linkedinUrl?: string | null
  }
}

export type CreateClientGroupDialog_ClientGroupFragment = {
  __typename?: 'ClientGroup'
  id: string
  clients: Array<{ __typename?: 'ClientUsers'; id: string }>
}

export type CreateClientGroupDialog_CreateClientGroupMutationVariables = Exact<{
  name: Scalars['String']['input']
}>

export type CreateClientGroupDialog_CreateClientGroupMutation = {
  __typename?: 'Mutation'
  createClientGroup: {
    __typename?: 'ClientGroup'
    id: string
    clients: Array<{ __typename?: 'ClientUsers'; id: string }>
  }
}

export type CreateClientGroupDialog_AddUsersToClientGroupMutationVariables = Exact<{
  clientGroupId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type CreateClientGroupDialog_AddUsersToClientGroupMutation = {
  __typename?: 'Mutation'
  addUsersToClientGroup: {
    __typename?: 'ClientGroup'
    id: string
    clients: Array<{ __typename?: 'ClientUsers'; id: string }>
  }
}

export type CreateClientGroupDialog_ClientsQueryVariables = Exact<{ [key: string]: never }>

export type CreateClientGroupDialog_ClientsQuery = {
  __typename?: 'Query'
  clients: {
    __typename?: 'PaginatedClientUsers'
    data: Array<{
      __typename?: 'ClientUsers'
      id: string
      user: { __typename?: 'Users'; firstName?: string | null; lastName?: string | null }
    }>
  }
}

export type EditClientGroupDialog_ClientGroupsQueryVariables = Exact<{
  clientGroupId: Scalars['ID']['input']
}>

export type EditClientGroupDialog_ClientGroupsQuery = {
  __typename?: 'Query'
  clientGroups: Array<{
    __typename?: 'ClientGroup'
    id: string
    name: string
    clients: Array<{
      __typename?: 'ClientUsers'
      id: string
      user: {
        __typename?: 'Users'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
  }>
}

export type EditClientGroupDialog_ClientsQueryVariables = Exact<{ [key: string]: never }>

export type EditClientGroupDialog_ClientsQuery = {
  __typename?: 'Query'
  clients: {
    __typename?: 'PaginatedClientUsers'
    pagination: { __typename?: 'Pagination'; page: number; pageSize: number }
    data: Array<{
      __typename?: 'ClientUsers'
      id: string
      user: {
        __typename?: 'Users'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
  }
}

export type EditClientGroupDialog_EditClientGroupMutationVariables = Exact<{
  clientGroupId: Scalars['ID']['input']
  userIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}>

export type EditClientGroupDialog_EditClientGroupMutation = {
  __typename?: 'Mutation'
  editClientGroup: { __typename?: 'ClientGroup'; id: string }
}

export type EnhanceAchievementDialog_GenerateOneJobAchievementMutationVariables = Exact<{
  workPositionId: Scalars['ID']['input']
  index: Scalars['Int']['input']
  keywords?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type EnhanceAchievementDialog_GenerateOneJobAchievementMutation = {
  __typename?: 'Mutation'
  generateOneJobAchievement: string
}

export type EnhanceSummaryDialog_GenerateProfessionalSummaryMutationVariables = Exact<{
  resumeId: Scalars['ID']['input']
  useExisting: Scalars['Boolean']['input']
  keywords?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type EnhanceSummaryDialog_GenerateProfessionalSummaryMutation = {
  __typename?: 'Mutation'
  generateProfessionalSummary: string
}

export type FeedbackDialog_CreateFeedbackMutationVariables = Exact<{
  subject?: InputMaybe<Scalars['String']['input']>
  feedback: Scalars['String']['input']
}>

export type FeedbackDialog_CreateFeedbackMutation = {
  __typename?: 'Mutation'
  createFeedback: string
}

export type ImportResumeFromLinkedinDialog_ResumesQueryVariables = Exact<{ [key: string]: never }>

export type ImportResumeFromLinkedinDialog_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type ImportResumeFromLinkedinDialog_CreateResumeFromLinkedinUrlMutationVariables = Exact<{
  linkedinProfileUrl: Scalars['String']['input']
}>

export type ImportResumeFromLinkedinDialog_CreateResumeFromLinkedinUrlMutation = {
  __typename?: 'Mutation'
  createResumeFromLinkedinUrl: { __typename?: 'Resumes'; id: string }
}

export type NewApplicationDialog_ResumesQueryVariables = Exact<{ [key: string]: never }>

export type NewApplicationDialog_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type NewApplicationDialog_CreateUsersJobMutationVariables = Exact<{
  applicationInstructions?: InputMaybe<Scalars['String']['input']>
  contact?: InputMaybe<ContactsInputObject>
  isRemote?: InputMaybe<Scalars['Boolean']['input']>
  jobDetails?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  payPeriod?: InputMaybe<UsersJobsPayPeriodEnum>
  position: Scalars['String']['input']
  salaryMax?: InputMaybe<Scalars['Int']['input']>
  salaryMin?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<UsersJobsStatusEnum>
  rejectedStage?: InputMaybe<UsersJobsRejectedStageEnum>
  interviewStep?: InputMaybe<UsersJobsInterviewStepEnum>
  companyName: Scalars['String']['input']
  url?: InputMaybe<Scalars['String']['input']>
  resumeId?: InputMaybe<Scalars['ID']['input']>
}>

export type NewApplicationDialog_CreateUsersJobMutation = {
  __typename?: 'Mutation'
  createUsersJob: { __typename?: 'UsersJobs'; id: string; status: UsersJobsStatusEnum }
}

export type NewApplicationsDialog_UsersJobsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type NewApplicationsDialog_UsersJobsQuery = {
  __typename?: 'Query'
  usersJobs: Array<{
    __typename?: 'UsersJobs'
    id: string
    status: UsersJobsStatusEnum
    isRemote?: boolean | null
    jobDetails?: string | null
    location?: string | null
    notes?: string | null
    position: string
    salaryMax?: number | null
    salaryMin?: number | null
    companyName: string
    url?: string | null
    rejectedStage?: UsersJobsRejectedStageEnum | null
    interviewStep?: UsersJobsInterviewStepEnum | null
    payPeriod: UsersJobsPayPeriodEnum
    applicationInstructions?: string | null
  }>
}

export type NewApplicationsDialog_UpdateUsersJobMutationVariables = Exact<{
  id: Scalars['ID']['input']
  isRemote?: InputMaybe<Scalars['Boolean']['input']>
  jobDetails?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  position: Scalars['String']['input']
  salaryMax?: InputMaybe<Scalars['Int']['input']>
  salaryMin?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<UsersJobsStatusEnum>
  rejectedStage?: InputMaybe<UsersJobsRejectedStageEnum>
  interviewStep?: InputMaybe<UsersJobsInterviewStepEnum>
  companyName: Scalars['String']['input']
  url?: InputMaybe<Scalars['String']['input']>
  resumeId?: InputMaybe<Scalars['ID']['input']>
  payPeriod?: InputMaybe<UsersJobsPayPeriodEnum>
  applicationInstructions?: InputMaybe<Scalars['String']['input']>
}>

export type NewApplicationsDialog_UpdateUsersJobMutation = {
  __typename?: 'Mutation'
  updateUsersJob: { __typename?: 'UsersJobs'; id: string; status: UsersJobsStatusEnum }
}

export type NewCoverLetterDialog_UsersJobsQueryVariables = Exact<{
  usersJobIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  archived: Scalars['Boolean']['input']
}>

export type NewCoverLetterDialog_UsersJobsQuery = {
  __typename?: 'Query'
  usersJobs: Array<{
    __typename?: 'UsersJobs'
    id: string
    position: string
    companyName: string
    coverLetter?: { __typename?: 'UsersJobsCoverLetterType'; id: string } | null
  }>
}

export type NewCoverLetterDialog_ResumesQueryVariables = Exact<{ [key: string]: never }>

export type NewCoverLetterDialog_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type NewCoverLetterDialog_CreateUsersJobMutationVariables = Exact<{
  position: Scalars['String']['input']
  status?: InputMaybe<UsersJobsStatusEnum>
  companyName: Scalars['String']['input']
  jobDetails?: InputMaybe<Scalars['String']['input']>
}>

export type NewCoverLetterDialog_CreateUsersJobMutation = {
  __typename?: 'Mutation'
  createUsersJob: {
    __typename?: 'UsersJobs'
    id: string
    applicationInstructions?: string | null
    createdAt: any
    companyName: string
    isRemote?: boolean | null
    jobDetails?: string | null
    location?: string | null
    notes?: string | null
    payPeriod: UsersJobsPayPeriodEnum
    position: string
    salaryMax?: number | null
    salaryMin?: number | null
    status: UsersJobsStatusEnum
    updatedAt: any
    url?: string | null
    archived: boolean
    rejectedStage?: UsersJobsRejectedStageEnum | null
    interviewStep?: UsersJobsInterviewStepEnum | null
    numClientsApplied: number
    numClientsShared: number
    partnerArchived: boolean
    partnerCreatedByUser?: {
      __typename?: 'Users'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    contacts?: Array<{
      __typename?: 'Contacts'
      id: string
      email?: string | null
      name?: string | null
    }> | null
    resume?: { __typename?: 'Resumes'; id: string; name: string } | null
  }
}

export type NewCoverLetterDialog_CoverLetterUpsertMutationVariables = Exact<{
  usersJobId: Scalars['ID']['input']
  attributes: UsersJobsCoverLetterInputObject
}>

export type NewCoverLetterDialog_CoverLetterUpsertMutation = {
  __typename?: 'Mutation'
  coverLetterUpsert: {
    __typename?: 'UsersJobsCoverLetterType'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    candidateAddress?: string | null
    companyName?: string | null
    companyLocation?: string | null
    phoneNumber?: string | null
    body?: string | null
    footer?: string | null
    archived: boolean
    mostRecentUsedResumeForGenerate?: { __typename?: 'Resumes'; id: string } | null
  }
}

export type NewInterviewDialog_ResumeQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
}>

export type NewInterviewDialog_ResumeQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type NewInterviewDialog_InterviewStartMockMutationVariables = Exact<{
  jobTitle: Scalars['String']['input']
  jobDescription?: InputMaybe<Scalars['String']['input']>
  interviewType: InterviewsTypeEnum
  resumeId?: InputMaybe<Scalars['ID']['input']>
}>

export type NewInterviewDialog_InterviewStartMockMutation = {
  __typename?: 'Mutation'
  interviewStartMock: { __typename?: 'Interview'; id: string }
}

export type NewResumeDialog_ResumesQueryVariables = Exact<{ [key: string]: never }>

export type NewResumeDialog_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type NewResumeDialog_CreateResumeFromLinkedinUrlMutationVariables = Exact<{
  linkedinProfileUrl: Scalars['String']['input']
}>

export type NewResumeDialog_CreateResumeFromLinkedinUrlMutation = {
  __typename?: 'Mutation'
  createResumeFromLinkedinUrl: { __typename?: 'Resumes'; id: string }
}

export type RedeemCodeDialog_SubscriptionsRedeemLtdPromoCodeMutationVariables = Exact<{
  promoCode: Scalars['String']['input']
}>

export type RedeemCodeDialog_SubscriptionsRedeemLtdPromoCodeMutation = {
  __typename?: 'Mutation'
  subscriptionsRedeemLtdPromoCode: { __typename?: 'Users'; id: string; plan: SubscriptionPlanEnum }
}

export type ShareJobPostingDialog_UsersJobsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type ShareJobPostingDialog_UsersJobsQuery = {
  __typename?: 'Query'
  usersJobs: Array<{
    __typename?: 'UsersJobs'
    id: string
    position: string
    companyName: string
    clientsShared: Array<{ __typename?: 'Users'; id: string }>
  }>
}

export type ShareJobPostingDialog_ClientsQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']['input']>
}>

export type ShareJobPostingDialog_ClientsQuery = {
  __typename?: 'Query'
  clients: {
    __typename?: 'PaginatedClientUsers'
    pagination: { __typename?: 'Pagination'; page: number; totalPages: number }
    data: Array<{
      __typename?: 'ClientUsers'
      id: string
      user: {
        __typename?: 'Users'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
  }
}

export type ShareJobPostingDialog_ClientGroupsQueryVariables = Exact<{ [key: string]: never }>

export type ShareJobPostingDialog_ClientGroupsQuery = {
  __typename?: 'Query'
  clientGroups: Array<{ __typename?: 'ClientGroup'; id: string; name: string }>
}

export type ShareJobPostingDialog_ShareJobWithClientsMutationVariables = Exact<{
  usersJobId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  clientGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type ShareJobPostingDialog_ShareJobWithClientsMutation = {
  __typename?: 'Mutation'
  shareJobWithClients: { __typename?: 'UsersJobs'; id: string }
}

export type TailorResumeDialog_CreateUsersJobMutationVariables = Exact<{
  position: Scalars['String']['input']
  status?: InputMaybe<UsersJobsStatusEnum>
  companyName: Scalars['String']['input']
  jobDetails?: InputMaybe<Scalars['String']['input']>
}>

export type TailorResumeDialog_CreateUsersJobMutation = {
  __typename?: 'Mutation'
  createUsersJob: {
    __typename?: 'UsersJobs'
    id: string
    applicationInstructions?: string | null
    createdAt: any
    companyName: string
    isRemote?: boolean | null
    jobDetails?: string | null
    location?: string | null
    notes?: string | null
    payPeriod: UsersJobsPayPeriodEnum
    position: string
    salaryMax?: number | null
    salaryMin?: number | null
    status: UsersJobsStatusEnum
    updatedAt: any
    url?: string | null
    archived: boolean
    rejectedStage?: UsersJobsRejectedStageEnum | null
    interviewStep?: UsersJobsInterviewStepEnum | null
    numClientsApplied: number
    numClientsShared: number
    partnerArchived: boolean
    partnerCreatedByUser?: {
      __typename?: 'Users'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    contacts?: Array<{
      __typename?: 'Contacts'
      id: string
      email?: string | null
      name?: string | null
    }> | null
    resume?: { __typename?: 'Resumes'; id: string; name: string } | null
  }
}

export type TailorResumeDialog_OptimizeResumeForJobMutationVariables = Exact<{
  keywords?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  resumeId: Scalars['ID']['input']
  skills?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  usersJobId: Scalars['ID']['input']
}>

export type TailorResumeDialog_OptimizeResumeForJobMutation = {
  __typename?: 'Mutation'
  optimizeResumeForJob: {
    __typename?: 'Resumes'
    id: string
    uniqueId?: string | null
    createdAt: any
    updatedAt: any
    name: string
    archived: boolean
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    linkedinUrl?: string | null
    phoneNumber?: string | null
    professionalSummary?: string | null
    website?: string | null
    location?: string | null
    suggestedSkills: Array<string>
    documentUrl?: string | null
    documentType?: ResumeDocumentType | null
    showOriginalDocument: boolean
    hideEducations: boolean
    hideProjects: boolean
    hideSkills: boolean
    hideSummary: boolean
    hideCertifications: boolean
    hideWorkExperiences: boolean
    hideCourseworks: boolean
    hideInvolvements: boolean
    sectionsOrder: Array<ResumeSortableSections>
    template: ResumesTemplateEnum
    templateColorEnum?: ResumesTemplateColorEnum | null
    aiGenerated: boolean
    courseworks: Array<{
      __typename?: 'ResumesCoursework'
      id: string
      name?: string | null
      institutionName?: string | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnCourse: boolean
    }>
    educations: Array<{
      __typename?: 'ResumesEducation'
      id: string
      additionalInformation?: string | null
      degree?: string | null
      endDate?: any | null
      startDate?: any | null
      gpa?: string | null
      institutionName: string
      location?: string | null
      currentlyInEducation: boolean
    }>
    involvements: Array<{
      __typename?: 'ResumesInvolvement'
      id: string
      name?: string | null
      organizationName?: string | null
      startDate?: any | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnInvolvement: boolean
    }>
    projects: Array<{
      __typename?: 'ResumesProject'
      id: string
      achievements: Array<string>
      name: string
      endDate?: any | null
      startDate?: any | null
      currentlyWorkingOnProject: boolean
    }>
    workExperiences: Array<{
      __typename?: 'ResumesWorkExperience'
      id: string
      companyDescription?: string | null
      companyName: string
      simpleAnalysis: Array<{
        __typename?: 'FieldMessageType'
        field: string
        errorMessage?: string | null
      }>
      workPositions: Array<{
        __typename?: 'ResumesWorkPosition'
        id: string
        achievements: Array<string>
        location?: string | null
        name: string
        startDate?: any | null
        endDate?: any | null
        currentlyInPosition: boolean
        simpleAnalysis: Array<{
          __typename?: 'FieldMessageType'
          field: string
          errorMessage?: string | null
        }>
      }>
    }>
    groupedSkills: Array<{
      __typename?: 'ResumesGroupedSkill'
      id: string
      category?: string | null
      skills: Array<string>
    }>
    simpleAnalysis: Array<{
      __typename?: 'FieldMessageType'
      field: string
      errorMessage?: string | null
    }>
    certifications: Array<{
      __typename?: 'ResumesCertification'
      id: string
      name: string
      completionDate?: any | null
      provider?: string | null
    }>
  }
}

export type TailorResumeDialog_FetchJobKeywordsMutationVariables = Exact<{
  usersJobId: Scalars['ID']['input']
}>

export type TailorResumeDialog_FetchJobKeywordsMutation = {
  __typename?: 'Mutation'
  fetchJobKeywords: Array<string>
}

export type TailorResumeDialog_FetchMissingSkillsOfJobResumeMutationVariables = Exact<{
  usersJobId: Scalars['ID']['input']
  resumeId: Scalars['ID']['input']
}>

export type TailorResumeDialog_FetchMissingSkillsOfJobResumeMutation = {
  __typename?: 'Mutation'
  fetchMissingSkillsOfJobResume: Array<string>
}

export type ResumeTemplateDrawer_UpdateTemplateMutationVariables = Exact<{
  resumeId: Scalars['ID']['input']
  template: ResumesTemplateEnum
  templateColorEnum?: InputMaybe<ResumesTemplateColorEnum>
}>

export type ResumeTemplateDrawer_UpdateTemplateMutation = {
  __typename?: 'Mutation'
  updateTemplateResume: {
    __typename?: 'Resumes'
    id: string
    template: ResumesTemplateEnum
    templateColorEnum?: ResumesTemplateColorEnum | null
  }
}

export type AdvisorSheet_ClientsQueryVariables = Exact<{
  advisorIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  page: Scalars['Int']['input']
  perPage: Scalars['Int']['input']
}>

export type AdvisorSheet_ClientsQuery = {
  __typename?: 'Query'
  clients: {
    __typename?: 'PaginatedClientUsers'
    pagination: { __typename?: 'Pagination'; page: number; totalPages: number }
    data: Array<{
      __typename?: 'ClientUsers'
      user: {
        __typename?: 'Users'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
  }
}

export type AdvisorSheet_AssignClientToAdvisorMutationVariables = Exact<{
  advisorId: Scalars['ID']['input']
  clientIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type AdvisorSheet_AssignClientToAdvisorMutation = {
  __typename?: 'Mutation'
  assignClientToAdvisor: string
}

export type ApplicationToolbar_UsersJobsArchivedQueryVariables = Exact<{
  usersJobIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type ApplicationToolbar_UsersJobsArchivedQuery = {
  __typename?: 'Query'
  usersJobs: Array<{ __typename?: 'UsersJobs'; id: string; archived: boolean }>
}

export type ApplicationToolbar_UpdateUsersJobArchiveMutationVariables = Exact<{
  id: Scalars['ID']['input']
  archive?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ApplicationToolbar_UpdateUsersJobArchiveMutation = {
  __typename?: 'Mutation'
  updateUsersJob: { __typename?: 'UsersJobs'; id: string; archived: boolean }
}

export type BoardToolbar_UsersJobsQueryVariables = Exact<{ [key: string]: never }>

export type BoardToolbar_UsersJobsQuery = {
  __typename?: 'Query'
  usersJobs: Array<{ __typename?: 'UsersJobs'; id: string }>
}

export type ClientBoardToolbar_ClientQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type ClientBoardToolbar_ClientQuery = {
  __typename?: 'Query'
  client: {
    __typename?: 'Users'
    id: string
    firstName?: string | null
    lastName?: string | null
    email: string
  }
}

export type ClientGroupToolbar_ClientGroupQueryVariables = Exact<{
  clientGroupId: Scalars['ID']['input']
}>

export type ClientGroupToolbar_ClientGroupQuery = {
  __typename?: 'Query'
  clientGroups: Array<{ __typename?: 'ClientGroup'; id: string; name: string }>
}

export type ClientResumeToolbar_ClientQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type ClientResumeToolbar_ClientQuery = {
  __typename?: 'Query'
  client: {
    __typename?: 'Users'
    id: string
    firstName?: string | null
    lastName?: string | null
    email: string
  }
}

export type ClientToolbar_ClientQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type ClientToolbar_ClientQuery = {
  __typename?: 'Query'
  client: {
    __typename?: 'Users'
    id: string
    firstName?: string | null
    lastName?: string | null
    email: string
  }
}

export type ClientsToolbar_AdvisorsQueryVariables = Exact<{ [key: string]: never }>

export type ClientsToolbar_AdvisorsQuery = {
  __typename?: 'Query'
  advisors: {
    __typename?: 'PaginatedAdvisorUsers'
    pagination: { __typename?: 'Pagination'; page: number; totalPages: number }
    data: Array<{
      __typename?: 'AdvisorUsers'
      id: string
      user: {
        __typename?: 'Users'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
  }
}

export type CoverLetterToolbar_CoverLettersQueryVariables = Exact<{
  coverLetterId: Scalars['ID']['input']
}>

export type CoverLetterToolbar_CoverLettersQuery = {
  __typename?: 'Query'
  coverLetters: Array<{ __typename?: 'UsersJobsCoverLetterType'; id: string; archived: boolean }>
}

export type CoverLetterToolbar_UpdateArchivedCoverLetterMutationVariables = Exact<{
  coverLetterId: Scalars['ID']['input']
  archived: Scalars['Boolean']['input']
}>

export type CoverLetterToolbar_UpdateArchivedCoverLetterMutation = {
  __typename?: 'Mutation'
  updateArchivedCoverLetter: {
    __typename?: 'UsersJobsCoverLetterType'
    id: string
    archived: boolean
  }
}

export type CoverLettersToolbar_CoverLettersQueryVariables = Exact<{ [key: string]: never }>

export type CoverLettersToolbar_CoverLettersQuery = {
  __typename?: 'Query'
  coverLetters: Array<{ __typename?: 'UsersJobsCoverLetterType'; id: string }>
}

export type InterviewsToolbar_InterviewsQueryVariables = Exact<{ [key: string]: never }>

export type InterviewsToolbar_InterviewsQuery = {
  __typename?: 'Query'
  interviews: Array<{ __typename?: 'Interview'; id: string }>
}

export type JobPostingsToolbar_ExportCsvMutationVariables = Exact<{ [key: string]: never }>

export type JobPostingsToolbar_ExportCsvMutation = {
  __typename?: 'Mutation'
  exportUsersJobToCsv: string
}

export type ResumeBuilderToolbar_ArchiveResumeMutationVariables = Exact<{
  id: Scalars['ID']['input']
  archived: Scalars['Boolean']['input']
}>

export type ResumeBuilderToolbar_ArchiveResumeMutation = {
  __typename?: 'Mutation'
  updateArchivedResume: { __typename?: 'Resumes'; id: string; archived: boolean }
}

export type ResumeBuilderToolbar_GetResumeQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ResumeBuilderToolbar_GetResumeQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; archived: boolean }>
}

export type ResumesToolbar_ResumesQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ResumesToolbar_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type CreateResumeMutationVariables = Exact<{
  name: Scalars['String']['input']
}>

export type CreateResumeMutation = {
  __typename?: 'Mutation'
  createResume: { __typename?: 'Resumes'; id: string; name: string }
}

export type AuthResponseFragment = {
  __typename?: 'AuthResponse'
  token?: string | null
  user?: { __typename?: 'Users'; id: string; role: UsersRoleEnum } | null
}

export type ProviderAuthenticationMutationVariables = Exact<{
  data: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  provider: AuthProviderTypeEnum
  resumeUniqueId?: InputMaybe<Scalars['ID']['input']>
  referredByUserUniqueId?: InputMaybe<Scalars['ID']['input']>
}>

export type ProviderAuthenticationMutation = {
  __typename?: 'Mutation'
  authProviderAuthenticate: {
    __typename?: 'AuthResponse'
    token?: string | null
    user?: { __typename?: 'Users'; id: string; role: UsersRoleEnum } | null
  }
}

export type CredentialsAuthenticationSignInMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type CredentialsAuthenticationSignInMutation = {
  __typename?: 'Mutation'
  authSignIn: {
    __typename?: 'AuthResponse'
    token?: string | null
    user?: { __typename?: 'Users'; id: string; role: UsersRoleEnum } | null
  }
}

export type CredentialsAuthenticationSignUpMutationVariables = Exact<{
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  referredByUserUniqueId?: InputMaybe<Scalars['ID']['input']>
  resumeUniqueId?: InputMaybe<Scalars['ID']['input']>
}>

export type CredentialsAuthenticationSignUpMutation = {
  __typename?: 'Mutation'
  authSignUp: {
    __typename?: 'AuthResponse'
    token?: string | null
    user?: { __typename?: 'Users'; id: string; role: UsersRoleEnum } | null
  }
}

export type CredentialsAuthenticationEmailVerifyMutationVariables = Exact<{
  emailVerificationCode: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type CredentialsAuthenticationEmailVerifyMutation = {
  __typename?: 'Mutation'
  userEmailVerify: {
    __typename?: 'AuthResponse'
    token?: string | null
    user?: { __typename?: 'Users'; id: string; role: UsersRoleEnum } | null
  }
}

export type ResumesQueryVariables = Exact<{ [key: string]: never }>

export type ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string }>
}

export type RootQueryVariables = Exact<{ [key: string]: never }>

export type RootQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'Users'
    id: string
    completedAllChecklistItems: boolean
    role: UsersRoleEnum
  }
  resumes: Array<{ __typename?: 'Resumes'; id: string }>
}

export type UploadResumeMutationVariables = Exact<{
  uploadSignedId: Scalars['ID']['input']
  name: Scalars['String']['input']
}>

export type UploadResumeMutation = {
  __typename?: 'Mutation'
  uploadResume: { __typename?: 'Resumes'; id: string; name: string }
}

export type UsersJobsFragment = {
  __typename?: 'UsersJobs'
  id: string
  applicationInstructions?: string | null
  createdAt: any
  companyName: string
  isRemote?: boolean | null
  jobDetails?: string | null
  location?: string | null
  notes?: string | null
  payPeriod: UsersJobsPayPeriodEnum
  position: string
  salaryMax?: number | null
  salaryMin?: number | null
  status: UsersJobsStatusEnum
  updatedAt: any
  url?: string | null
  archived: boolean
  rejectedStage?: UsersJobsRejectedStageEnum | null
  interviewStep?: UsersJobsInterviewStepEnum | null
  numClientsApplied: number
  numClientsShared: number
  partnerArchived: boolean
  partnerCreatedByUser?: {
    __typename?: 'Users'
    id: string
    firstName?: string | null
    lastName?: string | null
  } | null
  contacts?: Array<{
    __typename?: 'Contacts'
    id: string
    email?: string | null
    name?: string | null
  }> | null
  resume?: { __typename?: 'Resumes'; id: string; name: string } | null
}

export type UseBillingPlan_SubscriptionSessionCreateMutationVariables = Exact<{
  plan: SubscriptionPlanEnum
}>

export type UseBillingPlan_SubscriptionSessionCreateMutation = {
  __typename?: 'Mutation'
  subscriptionsSessionCreate: string
}

export type UseBillingPlan_BillingPortalUrlMutationVariables = Exact<{ [key: string]: never }>

export type UseBillingPlan_BillingPortalUrlMutation = {
  __typename?: 'Mutation'
  billingPortalUrl: string
}

export type UseBrowserExtensionToast_UserQueryVariables = Exact<{ [key: string]: never }>

export type UseBrowserExtensionToast_UserQuery = {
  __typename?: 'Query'
  user: { __typename?: 'Users'; id: string; usedChromeExtension: boolean }
}

export type UseCreateResume_ResumesQueryVariables = Exact<{ [key: string]: never }>

export type UseCreateResume_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type UsersFragment = {
  __typename?: 'Users'
  id: string
  uniqueId: string
  role: UsersRoleEnum
  email: string
  firstName?: string | null
  lastName?: string | null
  plan: SubscriptionPlanEnum
  tokenCoverLetter: number
  tokenInterviewQuestions: number
  tokenJobMatch: number
  tokenProfessionalSummary: number
  tokenSalaryInsights: number
  tokenLearnSkills: number
  tokenAchievements: number
  tokenInterview: number
  usedChromeExtension: boolean
  numUploadedResumes: number
  numStartedInterviews: number
  referralTokens: number
  hasSubscription: boolean
  isAdvisoryClient: boolean
  isAdvisoryOrgAccount: boolean
}

export type UseCurrentUser_UsersQueryVariables = Exact<{ [key: string]: never }>

export type UseCurrentUser_UsersQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'Users'
    id: string
    uniqueId: string
    role: UsersRoleEnum
    email: string
    firstName?: string | null
    lastName?: string | null
    plan: SubscriptionPlanEnum
    tokenCoverLetter: number
    tokenInterviewQuestions: number
    tokenJobMatch: number
    tokenProfessionalSummary: number
    tokenSalaryInsights: number
    tokenLearnSkills: number
    tokenAchievements: number
    tokenInterview: number
    usedChromeExtension: boolean
    numUploadedResumes: number
    numStartedInterviews: number
    referralTokens: number
    hasSubscription: boolean
    isAdvisoryClient: boolean
    isAdvisoryOrgAccount: boolean
  }
}

export type UseUploadResume_ResumesQueryVariables = Exact<{ [key: string]: never }>

export type UseUploadResume_ResumesQuery = {
  __typename?: 'Query'
  resumes: Array<{ __typename?: 'Resumes'; id: string; name: string }>
}

export type ResumesFragment = {
  __typename?: 'Resumes'
  id: string
  uniqueId?: string | null
  createdAt: any
  updatedAt: any
  name: string
  archived: boolean
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  linkedinUrl?: string | null
  phoneNumber?: string | null
  professionalSummary?: string | null
  website?: string | null
  location?: string | null
  suggestedSkills: Array<string>
  documentUrl?: string | null
  documentType?: ResumeDocumentType | null
  showOriginalDocument: boolean
  hideEducations: boolean
  hideProjects: boolean
  hideSkills: boolean
  hideSummary: boolean
  hideCertifications: boolean
  hideWorkExperiences: boolean
  hideCourseworks: boolean
  hideInvolvements: boolean
  sectionsOrder: Array<ResumeSortableSections>
  template: ResumesTemplateEnum
  templateColorEnum?: ResumesTemplateColorEnum | null
  aiGenerated: boolean
  courseworks: Array<{
    __typename?: 'ResumesCoursework'
    id: string
    name?: string | null
    institutionName?: string | null
    endDate?: any | null
    achievements: Array<string>
    currentlyWorkingOnCourse: boolean
  }>
  educations: Array<{
    __typename?: 'ResumesEducation'
    id: string
    additionalInformation?: string | null
    degree?: string | null
    endDate?: any | null
    startDate?: any | null
    gpa?: string | null
    institutionName: string
    location?: string | null
    currentlyInEducation: boolean
  }>
  involvements: Array<{
    __typename?: 'ResumesInvolvement'
    id: string
    name?: string | null
    organizationName?: string | null
    startDate?: any | null
    endDate?: any | null
    achievements: Array<string>
    currentlyWorkingOnInvolvement: boolean
  }>
  projects: Array<{
    __typename?: 'ResumesProject'
    id: string
    achievements: Array<string>
    name: string
    endDate?: any | null
    startDate?: any | null
    currentlyWorkingOnProject: boolean
  }>
  workExperiences: Array<{
    __typename?: 'ResumesWorkExperience'
    id: string
    companyDescription?: string | null
    companyName: string
    simpleAnalysis: Array<{
      __typename?: 'FieldMessageType'
      field: string
      errorMessage?: string | null
    }>
    workPositions: Array<{
      __typename?: 'ResumesWorkPosition'
      id: string
      achievements: Array<string>
      location?: string | null
      name: string
      startDate?: any | null
      endDate?: any | null
      currentlyInPosition: boolean
      simpleAnalysis: Array<{
        __typename?: 'FieldMessageType'
        field: string
        errorMessage?: string | null
      }>
    }>
  }>
  groupedSkills: Array<{
    __typename?: 'ResumesGroupedSkill'
    id: string
    category?: string | null
    skills: Array<string>
  }>
  simpleAnalysis: Array<{
    __typename?: 'FieldMessageType'
    field: string
    errorMessage?: string | null
  }>
  certifications: Array<{
    __typename?: 'ResumesCertification'
    id: string
    name: string
    completionDate?: any | null
    provider?: string | null
  }>
}

export type ResumeBuilder_GetResumeQueryVariables = Exact<{
  resumeId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type ResumeBuilder_GetResumeQuery = {
  __typename?: 'Query'
  resumes: Array<{
    __typename?: 'Resumes'
    id: string
    documentUrl?: string | null
    uniqueId?: string | null
    createdAt: any
    updatedAt: any
    name: string
    archived: boolean
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    linkedinUrl?: string | null
    phoneNumber?: string | null
    professionalSummary?: string | null
    website?: string | null
    location?: string | null
    suggestedSkills: Array<string>
    documentType?: ResumeDocumentType | null
    showOriginalDocument: boolean
    hideEducations: boolean
    hideProjects: boolean
    hideSkills: boolean
    hideSummary: boolean
    hideCertifications: boolean
    hideWorkExperiences: boolean
    hideCourseworks: boolean
    hideInvolvements: boolean
    sectionsOrder: Array<ResumeSortableSections>
    template: ResumesTemplateEnum
    templateColorEnum?: ResumesTemplateColorEnum | null
    aiGenerated: boolean
    courseworks: Array<{
      __typename?: 'ResumesCoursework'
      id: string
      name?: string | null
      institutionName?: string | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnCourse: boolean
    }>
    educations: Array<{
      __typename?: 'ResumesEducation'
      id: string
      additionalInformation?: string | null
      degree?: string | null
      endDate?: any | null
      startDate?: any | null
      gpa?: string | null
      institutionName: string
      location?: string | null
      currentlyInEducation: boolean
    }>
    involvements: Array<{
      __typename?: 'ResumesInvolvement'
      id: string
      name?: string | null
      organizationName?: string | null
      startDate?: any | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnInvolvement: boolean
    }>
    projects: Array<{
      __typename?: 'ResumesProject'
      id: string
      achievements: Array<string>
      name: string
      endDate?: any | null
      startDate?: any | null
      currentlyWorkingOnProject: boolean
    }>
    workExperiences: Array<{
      __typename?: 'ResumesWorkExperience'
      id: string
      companyDescription?: string | null
      companyName: string
      simpleAnalysis: Array<{
        __typename?: 'FieldMessageType'
        field: string
        errorMessage?: string | null
      }>
      workPositions: Array<{
        __typename?: 'ResumesWorkPosition'
        id: string
        achievements: Array<string>
        location?: string | null
        name: string
        startDate?: any | null
        endDate?: any | null
        currentlyInPosition: boolean
        simpleAnalysis: Array<{
          __typename?: 'FieldMessageType'
          field: string
          errorMessage?: string | null
        }>
      }>
    }>
    groupedSkills: Array<{
      __typename?: 'ResumesGroupedSkill'
      id: string
      category?: string | null
      skills: Array<string>
    }>
    simpleAnalysis: Array<{
      __typename?: 'FieldMessageType'
      field: string
      errorMessage?: string | null
    }>
    certifications: Array<{
      __typename?: 'ResumesCertification'
      id: string
      name: string
      completionDate?: any | null
      provider?: string | null
    }>
  }>
}

export type ResumeBuilder_GetResumeUnauthenticatedQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ResumeBuilder_GetResumeUnauthenticatedQuery = {
  __typename?: 'Query'
  resumeUnauthenticated?: {
    __typename?: 'Resumes'
    id: string
    uniqueId?: string | null
    createdAt: any
    updatedAt: any
    name: string
    archived: boolean
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    linkedinUrl?: string | null
    phoneNumber?: string | null
    professionalSummary?: string | null
    website?: string | null
    location?: string | null
    suggestedSkills: Array<string>
    documentUrl?: string | null
    documentType?: ResumeDocumentType | null
    showOriginalDocument: boolean
    hideEducations: boolean
    hideProjects: boolean
    hideSkills: boolean
    hideSummary: boolean
    hideCertifications: boolean
    hideWorkExperiences: boolean
    hideCourseworks: boolean
    hideInvolvements: boolean
    sectionsOrder: Array<ResumeSortableSections>
    template: ResumesTemplateEnum
    templateColorEnum?: ResumesTemplateColorEnum | null
    aiGenerated: boolean
    courseworks: Array<{
      __typename?: 'ResumesCoursework'
      id: string
      name?: string | null
      institutionName?: string | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnCourse: boolean
    }>
    educations: Array<{
      __typename?: 'ResumesEducation'
      id: string
      additionalInformation?: string | null
      degree?: string | null
      endDate?: any | null
      startDate?: any | null
      gpa?: string | null
      institutionName: string
      location?: string | null
      currentlyInEducation: boolean
    }>
    involvements: Array<{
      __typename?: 'ResumesInvolvement'
      id: string
      name?: string | null
      organizationName?: string | null
      startDate?: any | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnInvolvement: boolean
    }>
    projects: Array<{
      __typename?: 'ResumesProject'
      id: string
      achievements: Array<string>
      name: string
      endDate?: any | null
      startDate?: any | null
      currentlyWorkingOnProject: boolean
    }>
    workExperiences: Array<{
      __typename?: 'ResumesWorkExperience'
      id: string
      companyDescription?: string | null
      companyName: string
      simpleAnalysis: Array<{
        __typename?: 'FieldMessageType'
        field: string
        errorMessage?: string | null
      }>
      workPositions: Array<{
        __typename?: 'ResumesWorkPosition'
        id: string
        achievements: Array<string>
        location?: string | null
        name: string
        startDate?: any | null
        endDate?: any | null
        currentlyInPosition: boolean
        simpleAnalysis: Array<{
          __typename?: 'FieldMessageType'
          field: string
          errorMessage?: string | null
        }>
      }>
    }>
    groupedSkills: Array<{
      __typename?: 'ResumesGroupedSkill'
      id: string
      category?: string | null
      skills: Array<string>
    }>
    simpleAnalysis: Array<{
      __typename?: 'FieldMessageType'
      field: string
      errorMessage?: string | null
    }>
    certifications: Array<{
      __typename?: 'ResumesCertification'
      id: string
      name: string
      completionDate?: any | null
      provider?: string | null
    }>
  } | null
}

export type ResumeBuilder_GetClientResumeQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  resumeId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type ResumeBuilder_GetClientResumeQuery = {
  __typename?: 'Query'
  clientResumes: Array<{
    __typename?: 'Resumes'
    id: string
    uniqueId?: string | null
    createdAt: any
    updatedAt: any
    name: string
    archived: boolean
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    linkedinUrl?: string | null
    phoneNumber?: string | null
    professionalSummary?: string | null
    website?: string | null
    location?: string | null
    suggestedSkills: Array<string>
    documentUrl?: string | null
    documentType?: ResumeDocumentType | null
    showOriginalDocument: boolean
    hideEducations: boolean
    hideProjects: boolean
    hideSkills: boolean
    hideSummary: boolean
    hideCertifications: boolean
    hideWorkExperiences: boolean
    hideCourseworks: boolean
    hideInvolvements: boolean
    sectionsOrder: Array<ResumeSortableSections>
    template: ResumesTemplateEnum
    templateColorEnum?: ResumesTemplateColorEnum | null
    aiGenerated: boolean
    courseworks: Array<{
      __typename?: 'ResumesCoursework'
      id: string
      name?: string | null
      institutionName?: string | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnCourse: boolean
    }>
    educations: Array<{
      __typename?: 'ResumesEducation'
      id: string
      additionalInformation?: string | null
      degree?: string | null
      endDate?: any | null
      startDate?: any | null
      gpa?: string | null
      institutionName: string
      location?: string | null
      currentlyInEducation: boolean
    }>
    involvements: Array<{
      __typename?: 'ResumesInvolvement'
      id: string
      name?: string | null
      organizationName?: string | null
      startDate?: any | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnInvolvement: boolean
    }>
    projects: Array<{
      __typename?: 'ResumesProject'
      id: string
      achievements: Array<string>
      name: string
      endDate?: any | null
      startDate?: any | null
      currentlyWorkingOnProject: boolean
    }>
    workExperiences: Array<{
      __typename?: 'ResumesWorkExperience'
      id: string
      companyDescription?: string | null
      companyName: string
      simpleAnalysis: Array<{
        __typename?: 'FieldMessageType'
        field: string
        errorMessage?: string | null
      }>
      workPositions: Array<{
        __typename?: 'ResumesWorkPosition'
        id: string
        achievements: Array<string>
        location?: string | null
        name: string
        startDate?: any | null
        endDate?: any | null
        currentlyInPosition: boolean
        simpleAnalysis: Array<{
          __typename?: 'FieldMessageType'
          field: string
          errorMessage?: string | null
        }>
      }>
    }>
    groupedSkills: Array<{
      __typename?: 'ResumesGroupedSkill'
      id: string
      category?: string | null
      skills: Array<string>
    }>
    simpleAnalysis: Array<{
      __typename?: 'FieldMessageType'
      field: string
      errorMessage?: string | null
    }>
    certifications: Array<{
      __typename?: 'ResumesCertification'
      id: string
      name: string
      completionDate?: any | null
      provider?: string | null
    }>
  }>
}

export type ResumeBuilder_CreateResumeUnauthenticatedMutationVariables = Exact<{
  attributes?: InputMaybe<ResumesInputObject>
}>

export type ResumeBuilder_CreateResumeUnauthenticatedMutation = {
  __typename?: 'Mutation'
  createResumeUnauthenticated: {
    __typename?: 'Resumes'
    id: string
    uniqueId?: string | null
    createdAt: any
    updatedAt: any
    name: string
    archived: boolean
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    linkedinUrl?: string | null
    phoneNumber?: string | null
    professionalSummary?: string | null
    website?: string | null
    location?: string | null
    suggestedSkills: Array<string>
    documentUrl?: string | null
    documentType?: ResumeDocumentType | null
    showOriginalDocument: boolean
    hideEducations: boolean
    hideProjects: boolean
    hideSkills: boolean
    hideSummary: boolean
    hideCertifications: boolean
    hideWorkExperiences: boolean
    hideCourseworks: boolean
    hideInvolvements: boolean
    sectionsOrder: Array<ResumeSortableSections>
    template: ResumesTemplateEnum
    templateColorEnum?: ResumesTemplateColorEnum | null
    aiGenerated: boolean
    courseworks: Array<{
      __typename?: 'ResumesCoursework'
      id: string
      name?: string | null
      institutionName?: string | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnCourse: boolean
    }>
    educations: Array<{
      __typename?: 'ResumesEducation'
      id: string
      additionalInformation?: string | null
      degree?: string | null
      endDate?: any | null
      startDate?: any | null
      gpa?: string | null
      institutionName: string
      location?: string | null
      currentlyInEducation: boolean
    }>
    involvements: Array<{
      __typename?: 'ResumesInvolvement'
      id: string
      name?: string | null
      organizationName?: string | null
      startDate?: any | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnInvolvement: boolean
    }>
    projects: Array<{
      __typename?: 'ResumesProject'
      id: string
      achievements: Array<string>
      name: string
      endDate?: any | null
      startDate?: any | null
      currentlyWorkingOnProject: boolean
    }>
    workExperiences: Array<{
      __typename?: 'ResumesWorkExperience'
      id: string
      companyDescription?: string | null
      companyName: string
      simpleAnalysis: Array<{
        __typename?: 'FieldMessageType'
        field: string
        errorMessage?: string | null
      }>
      workPositions: Array<{
        __typename?: 'ResumesWorkPosition'
        id: string
        achievements: Array<string>
        location?: string | null
        name: string
        startDate?: any | null
        endDate?: any | null
        currentlyInPosition: boolean
        simpleAnalysis: Array<{
          __typename?: 'FieldMessageType'
          field: string
          errorMessage?: string | null
        }>
      }>
    }>
    groupedSkills: Array<{
      __typename?: 'ResumesGroupedSkill'
      id: string
      category?: string | null
      skills: Array<string>
    }>
    simpleAnalysis: Array<{
      __typename?: 'FieldMessageType'
      field: string
      errorMessage?: string | null
    }>
    certifications: Array<{
      __typename?: 'ResumesCertification'
      id: string
      name: string
      completionDate?: any | null
      provider?: string | null
    }>
  }
}

export type ResumeBuilder_UpdateResumeUnauthenticatedMutationVariables = Exact<{
  resumeUniqueId: Scalars['ID']['input']
  attributes: ResumesInputObject
}>

export type ResumeBuilder_UpdateResumeUnauthenticatedMutation = {
  __typename?: 'Mutation'
  updateResumeUnauthenticated: {
    __typename?: 'Resumes'
    id: string
    uniqueId?: string | null
    createdAt: any
    updatedAt: any
    name: string
    archived: boolean
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    linkedinUrl?: string | null
    phoneNumber?: string | null
    professionalSummary?: string | null
    website?: string | null
    location?: string | null
    suggestedSkills: Array<string>
    documentUrl?: string | null
    documentType?: ResumeDocumentType | null
    showOriginalDocument: boolean
    hideEducations: boolean
    hideProjects: boolean
    hideSkills: boolean
    hideSummary: boolean
    hideCertifications: boolean
    hideWorkExperiences: boolean
    hideCourseworks: boolean
    hideInvolvements: boolean
    sectionsOrder: Array<ResumeSortableSections>
    template: ResumesTemplateEnum
    templateColorEnum?: ResumesTemplateColorEnum | null
    aiGenerated: boolean
    courseworks: Array<{
      __typename?: 'ResumesCoursework'
      id: string
      name?: string | null
      institutionName?: string | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnCourse: boolean
    }>
    educations: Array<{
      __typename?: 'ResumesEducation'
      id: string
      additionalInformation?: string | null
      degree?: string | null
      endDate?: any | null
      startDate?: any | null
      gpa?: string | null
      institutionName: string
      location?: string | null
      currentlyInEducation: boolean
    }>
    involvements: Array<{
      __typename?: 'ResumesInvolvement'
      id: string
      name?: string | null
      organizationName?: string | null
      startDate?: any | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnInvolvement: boolean
    }>
    projects: Array<{
      __typename?: 'ResumesProject'
      id: string
      achievements: Array<string>
      name: string
      endDate?: any | null
      startDate?: any | null
      currentlyWorkingOnProject: boolean
    }>
    workExperiences: Array<{
      __typename?: 'ResumesWorkExperience'
      id: string
      companyDescription?: string | null
      companyName: string
      simpleAnalysis: Array<{
        __typename?: 'FieldMessageType'
        field: string
        errorMessage?: string | null
      }>
      workPositions: Array<{
        __typename?: 'ResumesWorkPosition'
        id: string
        achievements: Array<string>
        location?: string | null
        name: string
        startDate?: any | null
        endDate?: any | null
        currentlyInPosition: boolean
        simpleAnalysis: Array<{
          __typename?: 'FieldMessageType'
          field: string
          errorMessage?: string | null
        }>
      }>
    }>
    groupedSkills: Array<{
      __typename?: 'ResumesGroupedSkill'
      id: string
      category?: string | null
      skills: Array<string>
    }>
    simpleAnalysis: Array<{
      __typename?: 'FieldMessageType'
      field: string
      errorMessage?: string | null
    }>
    certifications: Array<{
      __typename?: 'ResumesCertification'
      id: string
      name: string
      completionDate?: any | null
      provider?: string | null
    }>
  }
}

export type ResumeBuilder_UpdateResumeMutationVariables = Exact<{
  resumeId: Scalars['ID']['input']
  attributes: ResumesInputObject
}>

export type ResumeBuilder_UpdateResumeMutation = {
  __typename?: 'Mutation'
  updateResume: {
    __typename?: 'Resumes'
    id: string
    uniqueId?: string | null
    createdAt: any
    updatedAt: any
    name: string
    archived: boolean
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    linkedinUrl?: string | null
    phoneNumber?: string | null
    professionalSummary?: string | null
    website?: string | null
    location?: string | null
    suggestedSkills: Array<string>
    documentUrl?: string | null
    documentType?: ResumeDocumentType | null
    showOriginalDocument: boolean
    hideEducations: boolean
    hideProjects: boolean
    hideSkills: boolean
    hideSummary: boolean
    hideCertifications: boolean
    hideWorkExperiences: boolean
    hideCourseworks: boolean
    hideInvolvements: boolean
    sectionsOrder: Array<ResumeSortableSections>
    template: ResumesTemplateEnum
    templateColorEnum?: ResumesTemplateColorEnum | null
    aiGenerated: boolean
    courseworks: Array<{
      __typename?: 'ResumesCoursework'
      id: string
      name?: string | null
      institutionName?: string | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnCourse: boolean
    }>
    educations: Array<{
      __typename?: 'ResumesEducation'
      id: string
      additionalInformation?: string | null
      degree?: string | null
      endDate?: any | null
      startDate?: any | null
      gpa?: string | null
      institutionName: string
      location?: string | null
      currentlyInEducation: boolean
    }>
    involvements: Array<{
      __typename?: 'ResumesInvolvement'
      id: string
      name?: string | null
      organizationName?: string | null
      startDate?: any | null
      endDate?: any | null
      achievements: Array<string>
      currentlyWorkingOnInvolvement: boolean
    }>
    projects: Array<{
      __typename?: 'ResumesProject'
      id: string
      achievements: Array<string>
      name: string
      endDate?: any | null
      startDate?: any | null
      currentlyWorkingOnProject: boolean
    }>
    workExperiences: Array<{
      __typename?: 'ResumesWorkExperience'
      id: string
      companyDescription?: string | null
      companyName: string
      simpleAnalysis: Array<{
        __typename?: 'FieldMessageType'
        field: string
        errorMessage?: string | null
      }>
      workPositions: Array<{
        __typename?: 'ResumesWorkPosition'
        id: string
        achievements: Array<string>
        location?: string | null
        name: string
        startDate?: any | null
        endDate?: any | null
        currentlyInPosition: boolean
        simpleAnalysis: Array<{
          __typename?: 'FieldMessageType'
          field: string
          errorMessage?: string | null
        }>
      }>
    }>
    groupedSkills: Array<{
      __typename?: 'ResumesGroupedSkill'
      id: string
      category?: string | null
      skills: Array<string>
    }>
    simpleAnalysis: Array<{
      __typename?: 'FieldMessageType'
      field: string
      errorMessage?: string | null
    }>
    certifications: Array<{
      __typename?: 'ResumesCertification'
      id: string
      name: string
      completionDate?: any | null
      provider?: string | null
    }>
  }
}

export type ResumeBuilder_ScoreResumeMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ResumeBuilder_ScoreResumeMutation = {
  __typename?: 'Mutation'
  scoreResume: {
    __typename?: 'ResumesScoreType'
    failed: number
    passed: number
    score: number
    total: number
    scoreFields: Array<{
      __typename?: 'ResumesScoreFieldType'
      didPass: boolean
      errorMessage?: string | null
      header: string
      tags?: Array<{ __typename?: 'ResumesScoreFieldTagType'; tagName: string }> | null
    }>
  }
}

export type Plan_SubscriptionSessionFetchMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
}>

export type Plan_SubscriptionSessionFetchMutation = {
  __typename?: 'Mutation'
  subscriptionsSessionFetch: string
}

export const UserJobsActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserJobsActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserJobsActivityFragment, unknown>
export const CoverLetterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverLetter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UsersJobsCoverLetterType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'candidateAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mostRecentUsedResumeForGenerate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoverLetterFragment, unknown>
export const Interviews_InterviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Interviews_interview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Interview' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fromUser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Interviews_InterviewFragment, unknown>
export const ResumesList_ResumesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResumesList_resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumesList_ResumesFragment, unknown>
export const ApplicationSelect_UsersJobsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApplicationSelect_usersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicationSelect_UsersJobsFragment, unknown>
export const UsersUpdateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersUpdate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Users' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersUpdateFragment, unknown>
export const CreateClientGroupDialog_ClientGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateClientGroupDialog_clientGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateClientGroupDialog_ClientGroupFragment, unknown>
export const AuthResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthResponseFragment, unknown>
export const UsersJobsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersJobsFragment, unknown>
export const UsersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Users' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Users' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenCoverLetter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenInterviewQuestions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenJobMatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenProfessionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenSalaryInsights' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenLearnSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenAchievements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenInterview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usedChromeExtension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numUploadedResumes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numStartedInterviews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralTokens' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSubscription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdvisoryClient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdvisoryOrgAccount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersFragment, unknown>
export const ResumesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOriginalDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideEducations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideProjects' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCertifications' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideWorkExperiences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCourseworks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideInvolvements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionsOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateColorEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseworks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnCourse' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gpa' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyInEducation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'involvements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnInvolvement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnProject' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workExperiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workPositions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentlyInPosition' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupedSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skills' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simpleAnalysis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumesFragment, unknown>
export const AdvisorsList_AdvisorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdvisorsList_advisors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ISO8601DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'toDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ISO8601DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersRoleEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdvisorSortEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortByDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortByDirectionEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'advisors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'toDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'toDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortByDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortByDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobsShared' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobsAdded' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'numOwnClients' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Users' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Users' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Users' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenCoverLetter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenInterviewQuestions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenJobMatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenProfessionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenSalaryInsights' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenLearnSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenAchievements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenInterview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usedChromeExtension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numUploadedResumes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numStartedInterviews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralTokens' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSubscription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdvisoryClient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdvisoryOrgAccount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdvisorsList_AdvisorsQuery, AdvisorsList_AdvisorsQueryVariables>
export const ClientGroupClientContent_ClientGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientGroupClientContent_clientGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientGroupIds' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientGroupClientContent_ClientGroupsQuery,
  ClientGroupClientContent_ClientGroupsQueryVariables
>
export const ClientGroupClientList_ClientGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientGroupClientList_clientGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientGroupIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numAllJobs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numInterviewedJobs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numOfferedJobs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numRejectedJobs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numAppliedJobs' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastActiveAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'advisor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientGroupClientList_ClientGroupsQuery,
  ClientGroupClientList_ClientGroupsQueryVariables
>
export const ClientBoard_ClientUsersJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientBoard_clientUsersJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientUsersJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientBoard_ClientUsersJobsQuery,
  ClientBoard_ClientUsersJobsQueryVariables
>
export const ClientContent_ClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientContent_client' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastActiveAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersJobs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeResumes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientContent_ClientQuery, ClientContent_ClientQueryVariables>
export const ClientResumes_ClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientResumes_client' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeResumes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientResumes_ClientQuery, ClientResumes_ClientQueryVariables>
export const ClientsList_ClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientsList_clients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ISO8601DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'toDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ISO8601DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advisorIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'toDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'toDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'advisorIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'advisorIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numAllJobs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numAppliedJobs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numInterviewedJobs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numOfferedJobs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numRejectedJobs' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastActiveAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'advisor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientsList_ClientsQuery, ClientsList_ClientsQueryVariables>
export const JobPostingsList_JobPostingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'JobPostingsList_jobPostings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'JobPostingSortEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortByDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortByDirectionEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedUsersJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortByDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortByDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientsShared' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobPostingsList_JobPostingsQuery,
  JobPostingsList_JobPostingsQueryVariables
>
export const JobPostingsList_UpdateUsersJobArchivedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JobPostingsList_updateUsersJobArchived' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUsersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobPostingsList_UpdateUsersJobArchivedMutation,
  JobPostingsList_UpdateUsersJobArchivedMutationVariables
>
export const LearnSkills_LearnSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LearnSkills_learnSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learnSkills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSync' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LearnSkills_LearnSkillsMutation,
  LearnSkills_LearnSkillsMutationVariables
>
export const LearnSkillsSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'LearnSkillsSubscription' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learnSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usersJobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'initial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requestId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'i' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LearnSkillsSubscriptionSubscription,
  LearnSkillsSubscriptionSubscriptionVariables
>
export const MatchScore_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MatchScore_resumes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupedSkills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'skills' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchScore_ResumesQuery, MatchScore_ResumesQueryVariables>
export const MatchScore_MatchScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MatchScore_matchScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'matchLevel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hardSkills' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'matches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchScore_MatchScoreMutation, MatchScore_MatchScoreMutationVariables>
export const SalaryInsights_SalaryInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SalaryInsights_salaryInsights' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salaryInsights' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SalaryInsights_SalaryInsightsMutation,
  SalaryInsights_SalaryInsightsMutationVariables
>
export const ApplicationContent_UsersJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApplicationContent_usersJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserJobsActivity' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tokenSalaryInsights' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tokenJobMatch' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tokenLearnSkills' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tokenAchievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserJobsActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicationContent_UsersJobsQuery,
  ApplicationContent_UsersJobsQueryVariables
>
export const ApplicationContent_UpdateUsersJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApplicationContent_updateUsersJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'applicationInstructions' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isRemote' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payPeriod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsPayPeriodEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMax' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMin' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsStatusEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rejectedStage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsRejectedStageEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'interviewStep' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsInterviewStepEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUsersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationInstructions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationInstructions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isRemote' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isRemote' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobDetails' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'location' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payPeriod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payPeriod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'position' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salaryMax' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMax' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salaryMin' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMin' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rejectedStage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rejectedStage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'interviewStep' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'interviewStep' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'url' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserJobsActivity' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserJobsActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicationContent_UpdateUsersJobMutation,
  ApplicationContent_UpdateUsersJobMutationVariables
>
export const Applications_UsersJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Applications_usersJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Applications_UsersJobsQuery, Applications_UsersJobsQueryVariables>
export const Applications_UpdateUsersJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Applications_updateUsersJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsStatusEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUsersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Applications_UpdateUsersJobStatusMutation,
  Applications_UpdateUsersJobStatusMutationVariables
>
export const Applications_UpdateUsersJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Applications_updateUsersJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsStatusEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUsersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Applications_UpdateUsersJobMutation,
  Applications_UpdateUsersJobMutationVariables
>
export const CoverLetterContent_CoverLettersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CoverLetterContent_coverLetters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'coverLetterId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverLetters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coverLetterIds' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'Variable', name: { kind: 'Name', value: 'coverLetterId' } }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverLetter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersJob' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverLetter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UsersJobsCoverLetterType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'candidateAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mostRecentUsedResumeForGenerate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoverLetterContent_CoverLettersQuery,
  CoverLetterContent_CoverLettersQueryVariables
>
export const CoverLettercontent_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CoverLettercontent_resumes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoverLettercontent_ResumesQuery,
  CoverLettercontent_ResumesQueryVariables
>
export const CoverLetterContent_GenerateCoverLetterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CoverLetterContent_generateCoverLetter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'coverLetterLength' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CoverLetterLengthEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'coverLetterTone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CoverLetterToneEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateCoverLetter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coverLetterLength' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'coverLetterLength' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coverLetterTone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'coverLetterTone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoverLetterContent_GenerateCoverLetterMutation,
  CoverLetterContent_GenerateCoverLetterMutationVariables
>
export const CoverLetterContent_CoverLetterUpsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CoverLetterContent_coverLetterUpsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UsersJobsCoverLetterInputObject' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverLetterUpsert' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attributes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverLetter' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverLetter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UsersJobsCoverLetterType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'candidateAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mostRecentUsedResumeForGenerate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoverLetterContent_CoverLetterUpsertMutation,
  CoverLetterContent_CoverLetterUpsertMutationVariables
>
export const CoverLettersList_CoverLettersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CoverLettersList_coverLetters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverLetters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersJob' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoverLettersList_CoverLettersQuery,
  CoverLettersList_CoverLettersQueryVariables
>
export const DashboardContent_ItemsDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardContent_itemsData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numJobs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedJobAiFeature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedResumeAiFeature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedChromeExtension' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workExperiences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workPositions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'simpleAnalysis' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardContent_ItemsDataQuery,
  DashboardContent_ItemsDataQueryVariables
>
export const Interview_GetInterviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Interview_getInterview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'interviewId' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interviews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'interviewIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'interviewId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interviewType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fromUser' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Interview_GetInterviewQuery, Interview_GetInterviewQueryVariables>
export const InterviewsSidebar_InterviewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InterviewsSidebar_interviews' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interviews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interviewType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewsSidebar_InterviewsQuery,
  InterviewsSidebar_InterviewsQueryVariables
>
export const Interviews_InterviewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Interviews_interviews' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interviews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interviewType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Interviews_InterviewsQuery, Interviews_InterviewsQueryVariables>
export const Interviews_InterviewAddMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Interviews_interviewAddMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'interviewId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interviewAddMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'interviewId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'interviewId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Interviews_interview' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Interviews_interview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Interview' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fromUser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Interviews_InterviewAddMessageMutation,
  Interviews_InterviewAddMessageMutationVariables
>
export const ListEmpty_CreateResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ListEmpty_createResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListEmpty_CreateResumeMutation,
  ListEmpty_CreateResumeMutationVariables
>
export const ResumesList_AllResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResumesList_allResumes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumesList_AllResumesQuery, ResumesList_AllResumesQueryVariables>
export const ResumesList_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResumesList_resumes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aiGenerated' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'aiGenerated' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'aiGenerated' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResumesList_resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResumesList_resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumesList_ResumesQuery, ResumesList_ResumesQueryVariables>
export const ResumeList_UpdateArchivedResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeList_updateArchivedResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateArchivedResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResumesList_resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResumesList_resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeList_UpdateArchivedResumeMutation,
  ResumeList_UpdateArchivedResumeMutationVariables
>
export const ResumeList_DuplicateResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeList_duplicateResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResumesList_resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResumesList_resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeList_DuplicateResumeMutation,
  ResumeList_DuplicateResumeMutationVariables
>
export const ForgotPassword_AuthPasswordResetRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForgotPassword_authPasswordResetRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authPasswordResetRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotPassword_AuthPasswordResetRequestMutation,
  ForgotPassword_AuthPasswordResetRequestMutationVariables
>
export const ResetPassword_ResetPasswordAllowedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResetPassword_resetPasswordAllowed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resetPasswordToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPasswordAllowed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resetPasswordToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resetPasswordToken' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPassword_ResetPasswordAllowedQuery,
  ResetPassword_ResetPasswordAllowedQueryVariables
>
export const ResetPassword_ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword_resetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resetPasswordToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authResetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resetPasswordToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resetPasswordToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPassword_ResetPasswordMutation,
  ResetPassword_ResetPasswordMutationVariables
>
export const EmailVerify_EmailVerifyAllowedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailVerify_emailVerifyAllowed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailVerificationCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailVerifyAllowed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailVerificationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailVerificationCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmailVerify_EmailVerifyAllowedQuery,
  EmailVerify_EmailVerifyAllowedQueryVariables
>
export const ApplicationSelect_UsersJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApplicationSelect_usersJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ApplicationSelect_usersJobs' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApplicationSelect_usersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicationSelect_UsersJobsQuery,
  ApplicationSelect_UsersJobsQueryVariables
>
export const Feedback_FeedbackCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Feedback_feedbackCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedback' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subject' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedback' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'feedback' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Feedback_FeedbackCreateMutation,
  Feedback_FeedbackCreateMutationVariables
>
export const Navigation_ItemsDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Navigation_itemsData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numJobs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedJobAiFeature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedResumeAiFeature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedChromeExtension' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workExperiences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workPositions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'simpleAnalysis' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Navigation_ItemsDataQuery, Navigation_ItemsDataQueryVariables>
export const Navigation_ClientGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Navigation_clientGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Navigation_ClientGroupsQuery, Navigation_ClientGroupsQueryVariables>
export const ResumesSelect_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResumesSelect_resumes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'optimized' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'aiGenerated' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'optimized' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumesSelect_ResumesQuery, ResumesSelect_ResumesQueryVariables>
export const UpdateLastPageLoaded_UpdateLastPageLoadedAtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLastPageLoaded_updateLastPageLoadedAt' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLastPageLoadedAt' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLastPageLoaded_UpdateLastPageLoadedAtMutation,
  UpdateLastPageLoaded_UpdateLastPageLoadedAtMutationVariables
>
export const AccountSettingsDialog_UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountSettingsDialog_user' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersUpdate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersUpdate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Users' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountSettingsDialog_UserQuery,
  AccountSettingsDialog_UserQueryVariables
>
export const AccountSettingsDialog_UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AccountSettingsDialog_updateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersInputObject' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attributes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersUpdate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersUpdate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Users' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountSettingsDialog_UpdateUserMutation,
  AccountSettingsDialog_UpdateUserMutationVariables
>
export const CreateClientGroupDialog_CreateClientGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateClientGroupDialog_createClientGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createClientGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreateClientGroupDialog_clientGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateClientGroupDialog_clientGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClientGroupDialog_CreateClientGroupMutation,
  CreateClientGroupDialog_CreateClientGroupMutationVariables
>
export const CreateClientGroupDialog_AddUsersToClientGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateClientGroupDialog_addUsersToClientGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUsersToClientGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreateClientGroupDialog_clientGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateClientGroupDialog_clientGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClientGroupDialog_AddUsersToClientGroupMutation,
  CreateClientGroupDialog_AddUsersToClientGroupMutationVariables
>
export const CreateClientGroupDialog_ClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateClientGroupDialog_clients' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClientGroupDialog_ClientsQuery,
  CreateClientGroupDialog_ClientsQueryVariables
>
export const EditClientGroupDialog_ClientGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EditClientGroupDialog_clientGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientGroupIds' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditClientGroupDialog_ClientGroupsQuery,
  EditClientGroupDialog_ClientGroupsQueryVariables
>
export const EditClientGroupDialog_ClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EditClientGroupDialog_clients' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditClientGroupDialog_ClientsQuery,
  EditClientGroupDialog_ClientsQueryVariables
>
export const EditClientGroupDialog_EditClientGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditClientGroupDialog_editClientGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editClientGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditClientGroupDialog_EditClientGroupMutation,
  EditClientGroupDialog_EditClientGroupMutationVariables
>
export const EnhanceAchievementDialog_GenerateOneJobAchievementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnhanceAchievementDialog_generateOneJobAchievement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workPositionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'index' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateOneJobAchievement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workPositionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workPositionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'index' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'index' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EnhanceAchievementDialog_GenerateOneJobAchievementMutation,
  EnhanceAchievementDialog_GenerateOneJobAchievementMutationVariables
>
export const EnhanceSummaryDialog_GenerateProfessionalSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnhanceSummaryDialog_generateProfessionalSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'useExisting' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateProfessionalSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'useExisting' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'useExisting' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EnhanceSummaryDialog_GenerateProfessionalSummaryMutation,
  EnhanceSummaryDialog_GenerateProfessionalSummaryMutationVariables
>
export const FeedbackDialog_CreateFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FeedbackDialog_createFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedback' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subject' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedback' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'feedback' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FeedbackDialog_CreateFeedbackMutation,
  FeedbackDialog_CreateFeedbackMutationVariables
>
export const ImportResumeFromLinkedinDialog_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ImportResumeFromLinkedinDialog_resumes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImportResumeFromLinkedinDialog_ResumesQuery,
  ImportResumeFromLinkedinDialog_ResumesQueryVariables
>
export const ImportResumeFromLinkedinDialog_CreateResumeFromLinkedinUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportResumeFromLinkedinDialog_createResumeFromLinkedinUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkedinProfileUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResumeFromLinkedinUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkedinProfileUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkedinProfileUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImportResumeFromLinkedinDialog_CreateResumeFromLinkedinUrlMutation,
  ImportResumeFromLinkedinDialog_CreateResumeFromLinkedinUrlMutationVariables
>
export const NewApplicationDialog_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NewApplicationDialog_resumes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewApplicationDialog_ResumesQuery,
  NewApplicationDialog_ResumesQueryVariables
>
export const NewApplicationDialog_CreateUsersJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NewApplicationDialog_createUsersJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'applicationInstructions' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactsInputObject' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isRemote' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payPeriod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsPayPeriodEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMax' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMin' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsStatusEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rejectedStage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsRejectedStageEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'interviewStep' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsInterviewStepEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUsersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationInstructions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationInstructions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contact' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contact' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isRemote' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isRemote' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobDetails' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'location' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payPeriod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payPeriod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'position' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salaryMax' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMax' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salaryMin' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMin' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'url' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rejectedStage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rejectedStage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'interviewStep' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'interviewStep' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewApplicationDialog_CreateUsersJobMutation,
  NewApplicationDialog_CreateUsersJobMutationVariables
>
export const NewApplicationsDialog_UsersJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NewApplicationsDialog_usersJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobIds' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'Variable', name: { kind: 'Name', value: 'id' } }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewApplicationsDialog_UsersJobsQuery,
  NewApplicationsDialog_UsersJobsQueryVariables
>
export const NewApplicationsDialog_UpdateUsersJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NewApplicationsDialog_updateUsersJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isRemote' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMax' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMin' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsStatusEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rejectedStage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsRejectedStageEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'interviewStep' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsInterviewStepEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payPeriod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsPayPeriodEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'applicationInstructions' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUsersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isRemote' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isRemote' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobDetails' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'location' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'position' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salaryMax' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMax' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salaryMin' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'salaryMin' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rejectedStage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rejectedStage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'interviewStep' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'interviewStep' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'url' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payPeriod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payPeriod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationInstructions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationInstructions' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewApplicationsDialog_UpdateUsersJobMutation,
  NewApplicationsDialog_UpdateUsersJobMutationVariables
>
export const NewCoverLetterDialog_UsersJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NewCoverLetterDialog_usersJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coverLetter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewCoverLetterDialog_UsersJobsQuery,
  NewCoverLetterDialog_UsersJobsQueryVariables
>
export const NewCoverLetterDialog_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NewCoverLetterDialog_resumes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewCoverLetterDialog_ResumesQuery,
  NewCoverLetterDialog_ResumesQueryVariables
>
export const NewCoverLetterDialog_CreateUsersJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NewCoverLetterDialog_createUsersJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsStatusEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUsersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'position' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobDetails' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewCoverLetterDialog_CreateUsersJobMutation,
  NewCoverLetterDialog_CreateUsersJobMutationVariables
>
export const NewCoverLetterDialog_CoverLetterUpsertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NewCoverLetterDialog_coverLetterUpsert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UsersJobsCoverLetterInputObject' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverLetterUpsert' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attributes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverLetter' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverLetter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UsersJobsCoverLetterType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'candidateAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mostRecentUsedResumeForGenerate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewCoverLetterDialog_CoverLetterUpsertMutation,
  NewCoverLetterDialog_CoverLetterUpsertMutationVariables
>
export const NewInterviewDialog_ResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NewInterviewDialog_resume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewInterviewDialog_ResumeQuery,
  NewInterviewDialog_ResumeQueryVariables
>
export const NewInterviewDialog_InterviewStartMockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NewInterviewDialog_interviewStartMock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobTitle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobDescription' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'interviewType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InterviewsTypeEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interviewStartMock' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobTitle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobTitle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobDescription' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobDescription' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'interviewType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'interviewType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewInterviewDialog_InterviewStartMockMutation,
  NewInterviewDialog_InterviewStartMockMutationVariables
>
export const NewResumeDialog_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NewResumeDialog_resumes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewResumeDialog_ResumesQuery, NewResumeDialog_ResumesQueryVariables>
export const NewResumeDialog_CreateResumeFromLinkedinUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NewResumeDialog_createResumeFromLinkedinUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkedinProfileUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResumeFromLinkedinUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkedinProfileUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkedinProfileUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewResumeDialog_CreateResumeFromLinkedinUrlMutation,
  NewResumeDialog_CreateResumeFromLinkedinUrlMutationVariables
>
export const RedeemCodeDialog_SubscriptionsRedeemLtdPromoCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RedeemCodeDialog_subscriptionsRedeemLtdPromoCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionsRedeemLtdPromoCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'promoCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RedeemCodeDialog_SubscriptionsRedeemLtdPromoCodeMutation,
  RedeemCodeDialog_SubscriptionsRedeemLtdPromoCodeMutationVariables
>
export const ShareJobPostingDialog_UsersJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShareJobPostingDialog_usersJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientsShared' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShareJobPostingDialog_UsersJobsQuery,
  ShareJobPostingDialog_UsersJobsQueryVariables
>
export const ShareJobPostingDialog_ClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShareJobPostingDialog_clients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'IntValue', value: '50' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShareJobPostingDialog_ClientsQuery,
  ShareJobPostingDialog_ClientsQueryVariables
>
export const ShareJobPostingDialog_ClientGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShareJobPostingDialog_clientGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShareJobPostingDialog_ClientGroupsQuery,
  ShareJobPostingDialog_ClientGroupsQueryVariables
>
export const ShareJobPostingDialog_ShareJobWithClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ShareJobPostingDialog_shareJobWithClients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareJobWithClients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientGroupIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShareJobPostingDialog_ShareJobWithClientsMutation,
  ShareJobPostingDialog_ShareJobWithClientsMutationVariables
>
export const TailorResumeDialog_CreateUsersJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TailorResumeDialog_createUsersJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobsStatusEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUsersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'position' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobDetails' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobDetails' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UsersJobs' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UsersJobs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersJobs' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interviewStep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsApplied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numClientsShared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerCreatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resume' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TailorResumeDialog_CreateUsersJobMutation,
  TailorResumeDialog_CreateUsersJobMutationVariables
>
export const TailorResumeDialog_OptimizeResumeForJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TailorResumeDialog_optimizeResumeForJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skills' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'optimizeResumeForJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skills' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skills' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOriginalDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideEducations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideProjects' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCertifications' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideWorkExperiences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCourseworks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideInvolvements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionsOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateColorEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseworks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnCourse' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gpa' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyInEducation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'involvements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnInvolvement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnProject' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workExperiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workPositions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentlyInPosition' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupedSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skills' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simpleAnalysis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TailorResumeDialog_OptimizeResumeForJobMutation,
  TailorResumeDialog_OptimizeResumeForJobMutationVariables
>
export const TailorResumeDialog_FetchJobKeywordsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TailorResumeDialog_fetchJobKeywords' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fetchJobKeywords' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TailorResumeDialog_FetchJobKeywordsMutation,
  TailorResumeDialog_FetchJobKeywordsMutationVariables
>
export const TailorResumeDialog_FetchMissingSkillsOfJobResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TailorResumeDialog_fetchMissingSkillsOfJobResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fetchMissingSkillsOfJobResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TailorResumeDialog_FetchMissingSkillsOfJobResumeMutation,
  TailorResumeDialog_FetchMissingSkillsOfJobResumeMutationVariables
>
export const ResumeTemplateDrawer_UpdateTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeTemplateDrawer_updateTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'template' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResumesTemplateEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateColorEnum' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResumesTemplateColorEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTemplateResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'template' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'template' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateColorEnum' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateColorEnum' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'template' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateColorEnum' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeTemplateDrawer_UpdateTemplateMutation,
  ResumeTemplateDrawer_UpdateTemplateMutationVariables
>
export const AdvisorSheet_ClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdvisorSheet_clients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advisorIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'advisorIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'advisorIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdvisorSheet_ClientsQuery, AdvisorSheet_ClientsQueryVariables>
export const AdvisorSheet_AssignClientToAdvisorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdvisorSheet_assignClientToAdvisor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'advisorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignClientToAdvisor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'advisorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'advisorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdvisorSheet_AssignClientToAdvisorMutation,
  AdvisorSheet_AssignClientToAdvisorMutationVariables
>
export const ApplicationToolbar_UsersJobsArchivedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApplicationToolbar_usersJobsArchived' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usersJobIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usersJobIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicationToolbar_UsersJobsArchivedQuery,
  ApplicationToolbar_UsersJobsArchivedQueryVariables
>
export const ApplicationToolbar_UpdateUsersJobArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApplicationToolbar_updateUsersJobArchive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archive' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUsersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archive' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicationToolbar_UpdateUsersJobArchiveMutation,
  ApplicationToolbar_UpdateUsersJobArchiveMutationVariables
>
export const BoardToolbar_UsersJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoardToolbar_UsersJobs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersJobs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoardToolbar_UsersJobsQuery, BoardToolbar_UsersJobsQueryVariables>
export const ClientBoardToolbar_ClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientBoardToolbar_client' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientBoardToolbar_ClientQuery,
  ClientBoardToolbar_ClientQueryVariables
>
export const ClientGroupToolbar_ClientGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientGroupToolbar_ClientGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientGroupIds' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'Variable', name: { kind: 'Name', value: 'clientGroupId' } }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientGroupToolbar_ClientGroupQuery,
  ClientGroupToolbar_ClientGroupQueryVariables
>
export const ClientResumeToolbar_ClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientResumeToolbar_client' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientResumeToolbar_ClientQuery,
  ClientResumeToolbar_ClientQueryVariables
>
export const ClientToolbar_ClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientToolbar_client' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientToolbar_ClientQuery, ClientToolbar_ClientQueryVariables>
export const ClientsToolbar_AdvisorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientsToolbar_advisors' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'advisors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'IntValue', value: '999999' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientsToolbar_AdvisorsQuery, ClientsToolbar_AdvisorsQueryVariables>
export const CoverLetterToolbar_CoverLettersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CoverLetterToolbar_coverLetters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'coverLetterId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverLetters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coverLetterIds' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'Variable', name: { kind: 'Name', value: 'coverLetterId' } }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoverLetterToolbar_CoverLettersQuery,
  CoverLetterToolbar_CoverLettersQueryVariables
>
export const CoverLetterToolbar_UpdateArchivedCoverLetterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CoverLetterToolbar_updateArchivedCoverLetter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'coverLetterId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateArchivedCoverLetter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coverLetterId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'coverLetterId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoverLetterToolbar_UpdateArchivedCoverLetterMutation,
  CoverLetterToolbar_UpdateArchivedCoverLetterMutationVariables
>
export const CoverLettersToolbar_CoverLettersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CoverLettersToolbar_coverLetters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverLetters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoverLettersToolbar_CoverLettersQuery,
  CoverLettersToolbar_CoverLettersQueryVariables
>
export const InterviewsToolbar_InterviewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InterviewsToolbar_interviews' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interviews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewsToolbar_InterviewsQuery,
  InterviewsToolbar_InterviewsQueryVariables
>
export const JobPostingsToolbar_ExportCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JobPostingsToolbar_exportCsv' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'exportUsersJobToCsv' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  JobPostingsToolbar_ExportCsvMutation,
  JobPostingsToolbar_ExportCsvMutationVariables
>
export const ResumeBuilderToolbar_ArchiveResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeBuilderToolbar_archiveResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateArchivedResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeBuilderToolbar_ArchiveResumeMutation,
  ResumeBuilderToolbar_ArchiveResumeMutationVariables
>
export const ResumeBuilderToolbar_GetResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResumeBuilderToolbar_getResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeIds' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'Variable', name: { kind: 'Name', value: 'id' } }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeBuilderToolbar_GetResumeQuery,
  ResumeBuilderToolbar_GetResumeQueryVariables
>
export const ResumesToolbar_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResumesToolbar_resumes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumesToolbar_ResumesQuery, ResumesToolbar_ResumesQueryVariables>
export const CreateResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateResumeMutation, CreateResumeMutationVariables>
export const ProviderAuthenticationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProviderAuthentication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthProviderTypeEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeUniqueId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referredByUserUniqueId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authProviderAuthenticate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'provider' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeUniqueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeUniqueId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referredByUserUniqueId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'referredByUserUniqueId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthResponse' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProviderAuthenticationMutation,
  ProviderAuthenticationMutationVariables
>
export const CredentialsAuthenticationSignInDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CredentialsAuthenticationSignIn' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authSignIn' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthResponse' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CredentialsAuthenticationSignInMutation,
  CredentialsAuthenticationSignInMutationVariables
>
export const CredentialsAuthenticationSignUpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CredentialsAuthenticationSignUp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referredByUserUniqueId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeUniqueId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authSignUp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referredByUserUniqueId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'referredByUserUniqueId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeUniqueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeUniqueId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthResponse' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CredentialsAuthenticationSignUpMutation,
  CredentialsAuthenticationSignUpMutationVariables
>
export const CredentialsAuthenticationEmailVerifyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CredentialsAuthenticationEmailVerify' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailVerificationCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userEmailVerify' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailVerificationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailVerificationCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthResponse' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CredentialsAuthenticationEmailVerifyMutation,
  CredentialsAuthenticationEmailVerifyMutationVariables
>
export const ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Resumes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumesQuery, ResumesQueryVariables>
export const RootDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Root' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAllChecklistItems' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RootQuery, RootQueryVariables>
export const UploadResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadSignedId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadSignedId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadSignedId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadResumeMutation, UploadResumeMutationVariables>
export const UseBillingPlan_SubscriptionSessionCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'useBillingPlan_subscriptionSessionCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plan' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionPlanEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionsSessionCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plan' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'plan' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseBillingPlan_SubscriptionSessionCreateMutation,
  UseBillingPlan_SubscriptionSessionCreateMutationVariables
>
export const UseBillingPlan_BillingPortalUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'useBillingPlan_billingPortalUrl' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'billingPortalUrl' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UseBillingPlan_BillingPortalUrlMutation,
  UseBillingPlan_BillingPortalUrlMutationVariables
>
export const UseBrowserExtensionToast_UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useBrowserExtensionToast_user' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedChromeExtension' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseBrowserExtensionToast_UserQuery,
  UseBrowserExtensionToast_UserQueryVariables
>
export const UseCreateResume_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useCreateResume_resumes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseCreateResume_ResumesQuery, UseCreateResume_ResumesQueryVariables>
export const UseCurrentUser_UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useCurrentUser_users' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Users' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Users' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Users' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenCoverLetter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenInterviewQuestions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenJobMatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenProfessionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenSalaryInsights' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenLearnSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenAchievements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenInterview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usedChromeExtension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numUploadedResumes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numStartedInterviews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralTokens' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSubscription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdvisoryClient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdvisoryOrgAccount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseCurrentUser_UsersQuery, UseCurrentUser_UsersQueryVariables>
export const UseUploadResume_ResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useUploadResume_resumes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseUploadResume_ResumesQuery, UseUploadResume_ResumesQueryVariables>
export const ResumeBuilder_GetResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResumeBuilder_getResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOriginalDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideEducations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideProjects' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCertifications' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideWorkExperiences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCourseworks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideInvolvements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionsOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateColorEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseworks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnCourse' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gpa' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyInEducation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'involvements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnInvolvement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnProject' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workExperiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workPositions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentlyInPosition' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupedSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skills' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simpleAnalysis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumeBuilder_GetResumeQuery, ResumeBuilder_GetResumeQueryVariables>
export const ResumeBuilder_GetResumeUnauthenticatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResumeBuilder_getResumeUnauthenticated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumeUnauthenticated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOriginalDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideEducations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideProjects' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCertifications' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideWorkExperiences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCourseworks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideInvolvements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionsOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateColorEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseworks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnCourse' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gpa' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyInEducation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'involvements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnInvolvement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnProject' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workExperiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workPositions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentlyInPosition' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupedSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skills' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simpleAnalysis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeBuilder_GetResumeUnauthenticatedQuery,
  ResumeBuilder_GetResumeUnauthenticatedQueryVariables
>
export const ResumeBuilder_GetClientResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResumeBuilder_getClientResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientResumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resumeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOriginalDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideEducations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideProjects' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCertifications' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideWorkExperiences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCourseworks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideInvolvements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionsOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateColorEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseworks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnCourse' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gpa' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyInEducation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'involvements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnInvolvement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnProject' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workExperiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workPositions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentlyInPosition' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupedSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skills' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simpleAnalysis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeBuilder_GetClientResumeQuery,
  ResumeBuilder_GetClientResumeQueryVariables
>
export const ResumeBuilder_CreateResumeUnauthenticatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeBuilder_createResumeUnauthenticated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResumesInputObject' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResumeUnauthenticated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attributes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOriginalDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideEducations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideProjects' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCertifications' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideWorkExperiences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCourseworks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideInvolvements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionsOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateColorEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseworks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnCourse' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gpa' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyInEducation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'involvements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnInvolvement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnProject' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workExperiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workPositions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentlyInPosition' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupedSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skills' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simpleAnalysis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeBuilder_CreateResumeUnauthenticatedMutation,
  ResumeBuilder_CreateResumeUnauthenticatedMutationVariables
>
export const ResumeBuilder_UpdateResumeUnauthenticatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeBuilder_updateResumeUnauthenticated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeUniqueId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResumesInputObject' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResumeUnauthenticated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeUniqueId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attributes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOriginalDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideEducations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideProjects' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCertifications' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideWorkExperiences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCourseworks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideInvolvements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionsOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateColorEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseworks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnCourse' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gpa' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyInEducation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'involvements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnInvolvement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnProject' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workExperiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workPositions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentlyInPosition' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupedSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skills' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simpleAnalysis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeBuilder_UpdateResumeUnauthenticatedMutation,
  ResumeBuilder_UpdateResumeUnauthenticatedMutationVariables
>
export const ResumeBuilder_UpdateResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeBuilder_updateResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResumesInputObject' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resumeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attributes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resumes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resumes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resumes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professionalSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOriginalDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideEducations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideProjects' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSkills' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCertifications' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideWorkExperiences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCourseworks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideInvolvements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionsOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateColorEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseworks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnCourse' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gpa' } },
                { kind: 'Field', name: { kind: 'Name', value: 'institutionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyInEducation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'involvements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnInvolvement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentlyWorkingOnProject' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workExperiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'simpleAnalysis' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workPositions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'achievements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentlyInPosition' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'simpleAnalysis' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupedSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skills' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'simpleAnalysis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completionDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeBuilder_UpdateResumeMutation,
  ResumeBuilder_UpdateResumeMutationVariables
>
export const ResumeBuilder_ScoreResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeBuilder_scoreResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scoreResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'passed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scoreFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'didPass' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tagName' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeBuilder_ScoreResumeMutation,
  ResumeBuilder_ScoreResumeMutationVariables
>
export const Plan_SubscriptionSessionFetchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Plan_subscriptionSessionFetch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionsSessionFetch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Plan_SubscriptionSessionFetchMutation,
  Plan_SubscriptionSessionFetchMutationVariables
>
